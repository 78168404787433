import actions from './actions'
import login from '@/Pages/Login.vue'
//import api from '../../../api';

const state = {
  overlay: false,
  client: null,
  showEditClientPopup: false,
  transaction: null,
  showEditTransactionPopup: false,
  payment: null,
  showEditPaymentPopup: false,
  showViewFilePopup: false,
  file: null,
  showRemoveClientPopup: false,
  showRemoveFilePopup: false,
  card: null,
  customer: null,
  showSetCardLimitPopup: false,
  showSetCardLimitCustomerPopup: false,
  showSuspendCardPopup: false,
  showAddCardPopup: false,
  setEditCardDataPopup: null,
  user: null,
  showEditUserPopup: false,
  showRemoveUserPopup: false,
  showUploadPricesPopup: false,
  showResetPasswordPopup: false,
  showBunchChangeCardStatusPopup: false,
  cardsChecked: [],
  showAddNewUserPopup: false,
  showUpdateTransactionsPopup: false,
  isAddTransactions: false,
  tableFilterPopup: null,
  tableFilterPopup_2: null,
  mouseOverFilter: false,
  columns: null,
  columns_2: null,
  dateRange: '',
  actionPopup: null,
  errorPopup: false,
  errorText: null,
  isSuccess: false,
  paginationFilters: [
    {
      name: 'page',
      value: 1
    },
    {
      name: 'perPage',
      value: 100
    }
  ],
  paginationFilters_2: [
    {
      name: 'page',
      value: 1
    },
    {
      name: 'perPage',
      value: 100
    }
  ],
  showSuretyInfoPopup: false,
  dataSuretyInfoPopup: null,
  showDiscountPopup: false,
  showDiscountFuelTypePopup: false,
  showDiscountRegionPopup: false,
  showDiscountRegionFuelTypePopup: false,
  showDiscountBlockDayPopup: false,
  showDiscountGoldPopup: false,
  showDiscountPartnersPopup: false,
  cardsReportFilters: [
    {
      filter: 'filter[card.customer_id]',
      value: 0
    },
    {
      filter: 'filter[card.number]',
      value: 0
    }
  ],
  showEditCardPopup: false,
  dataEditCardPopup: null,
  showRemoveReferencePopup: false,
  isSaveRemoveReferencePopup: 0,
  showUploadCardsPopup: false,
  showSetCardsForClientPopup: false,
  showIntegrationListPopup: false,
  showListVinksRegionsFuelPopup: false,
  showSendEmailsPopup: false,
  showNotificationSettingsPopup: false,
  showAdminNotificationSettingsPopup: false,
  showEndPriceGoldDatePopup: false,
  endPriceGoldDateObj: null,
}

const mutations = {
  TOGGLE_OVERLAY(state, payload) {
    state.overlay = payload
  },
  SET_CLIENT(state, payload) {
    state.client = payload
    state.client.balance = Number(state.client.balance).toFixed(2)
  },
  SHOW_EDIT_CLIENT(state, payload) {
    state.showEditClientPopup = payload
  },
  SET_TRANSACTION(state, payload) {
    state.transaction = payload
  },
  SHOW_EDIT_TRANSACTION(state, payload) {
    state.showEditTransactionPopup = payload
  },
  SET_PAYMENT(state, payload) {
    state.payment = payload
  },
  SHOW_EDIT_PAYMENT(state, payload) {
    state.showEditPaymentPopup = payload
  },
  SHOW_VIEW_FILE(state, payload) {
    state.showViewFilePopup = payload
  },
  SET_FILE(state, payload) {
    state.file = payload
  },
  SHOW_REMOVE_CLIENT(state, payload) {
    state.showRemoveClientPopup = payload
  },
  SHOW_REMOVE_FILE(state, payload) {
    state.showRemoveFilePopup = payload
  },
  SET_CARD(state, payload) {
    state.card = payload
  },
  SET_CUSTOMER(state, payload) {
    state.customer = payload
    if (state.customer?.balance) state.customer.balance = Number(state.customer.balance).toFixed(2)
  },
  SHOW_SET_CARD_LIMIT(state, payload) {
    state.showSetCardLimitPopup = payload
  },
  SHOW_SET_CARD_LIMIT_CUSTOMER(state, payload) {
    state.showSetCardLimitCustomerPopup = payload
  },
  SHOW_SUSPEND_CARD(state, payload) {
    state.showSuspendCardPopup = payload
  },
  SHOW_ADD_CARD(state, payload) {
    state.showAddCardPopup = payload
  },
  SET_EDIT_CART_DATA_POPUP(state, payload) {
    state.setEditCardDataPopup = payload
  },
  SET_USER(state, payload) {
    state.user = payload
    if (state.user?.balance) {
      state.user.balance = Number(state.user.balance).toFixed(2)
    }
  },
  SHOW_EDIT_USER(state, payload) {
    state.showEditUserPopup = payload
  },
  SHOW_REMOVE_USER(state, payload) {
    state.showRemoveUserPopup = payload
  },
  SHOW_UPLOAD_PRICES(state, payload) {
    state.showUploadPricesPopup = payload
  },
  SHOW_ADD_NEW_USER(state, payload) {
    state.showAddNewUserPopup = payload
  },
  SHOW_UPDATE_TRANSACTIONS(state, payload) {
    state.showUpdateTransactionsPopup = payload
  },
  IS_ADD_TRANSACTIONS(state, payload) {
    state.isAddTransactions = payload
  },
  SHOW_RESET_PASSWORD(state, payload) {
    state.showResetPasswordPopup = payload
  },
  SET_PAGINATION_FILTERS(state, payload) {
    state.paginationFilters = payload
  },
  RESET_PAGINATION_FILTERS(state) {
    state.paginationFilters = [
      {
        name: 'page',
        value: 1
      },
      {
        name: 'perPage',
        value: 100
      }
    ]
  },
  SET_PAGINATION_FILTERS_2(state, payload) {
    state.paginationFilters_2 = payload
  },
  RESET_PAGINATION_FILTERS_2(state) {
    state.paginationFilters_2 = [
      {
        name: 'page',
        value: 1
      },
      {
        name: 'perPage',
        value: 100
      }
    ]
  },
  SHOW_BUNCH_CHANGE_CARD_STATUS(state, payload) {
    state.showBunchChangeCardStatusPopup = payload
  },
  SET_CARDS_CHECKED(state, payload) {
    state.cardsChecked = payload
  },
  SET_TABLE_FILTER_POPUP(state, payload) {
    state.tableFilterPopup = payload
  },
  SET_TABLE_FILTER_POPUP_2(state, payload) {
    state.tableFilterPopup_2 = payload
  },
  SET_MOUSE_OVER_FILTER(state, payload) {
    state.mouseOverFilter = payload
  },
  SET_COLUMNS(state, payload) {
    state.columns = payload
  },
  SET_COLUMNS_2(state, payload) {
    state.columns_2 = payload
  },
  SET_DATE_RANGE(state, payload) {
    state.dateRange = payload
  },
  SET_ACTION_POPUP(state, payload) {
    state.actionPopup = payload
  },
  SET_ERROR_TEXT(state, payload) {
    state.errorText = payload
  },
  SET_IS_SUCCESS(state, payload) {
    state.isSuccess = payload
  },
  SHOW_ERROR_POPUP(state, payload) {
    state.errorPopup = payload
  },
  SHOW_SURETY_INFO_POPUP(state, payload) {
    state.showSuretyInfoPopup = payload
  },
  DATA_SURETY_INFO_POPUP(state, payload) {
    state.dataSuretyInfoPopup = payload
  },
  SET_SHOW_DISCOUNT_POPUP(state, payload) {
    state.showDiscountPopup = payload
  },
  SET_SHOW_DISCOUNT_FUEL_TYPE_POPUP(state, payload) {
    state.showDiscountFuelTypePopup = payload
  },
  SET_SHOW_DISCOUNT_REGION_POPUP(state, payload) {
    state.showDiscountRegionPopup = payload
  },
  SET_SHOW_DISCOUNT_REGION_FUEL_TYPE_POPUP(state, payload) {
    state.showDiscountRegionFuelTypePopup = payload
  },
  SET_SHOW_DISCOUNT_BLOCK_DAY_POPUP(state, payload) {
    state.showDiscountBlockDayPopup = payload
  },
  SET_SHOW_DISCOUNT_GOLD_POPUP(state, payload) {
    state.showDiscountGoldPopup = payload
  },
  SET_SHOW_DISCOUNT_PARTNERS_POPUP(state, payload) {
    state.showDiscountPartnersPopup = payload
  },
  SET_CARDS_REPORT_FILTERS(state, payload) {
    state.cardsReportFilters = payload
  },
  SET_SHOW_EDIT_CARD_POPUP(state, payload) {
    state.showEditCardPopup = payload
  },
  SET_DATA_EDIT_CARD_POPUP(state, payload) {
    state.dataEditCardPopup = payload
  },
  SET_SHOW_REMOVE_REFERENCE_POPUP(state, payload) {
    state.showRemoveReferencePopup = payload
  },
  SET_IS_SAVE_REMOVE_REFERENCE_POPUP(state, payload) {
    state.isSaveRemoveReferencePopup = payload
  },
  SET_SHOW_UPLOAD_CARDS_POPUP(state, payload) {
    state.showUploadCardsPopup = payload
  },
  SET_SHOW_CARDS_FOR_CLIENT_POPUP(state, payload) {
    state.showSetCardsForClientPopup = payload
  },
  SET_SHOW_INTEGRATION_LIST_POPUP(state, payload) {
    state.showIntegrationListPopup = payload
  },
  SET_SHOW_LIST_VINKS_REGIONS_FUEL_POPUP(state, payload) {
    state.showListVinksRegionsFuelPopup = payload
  },
  SET_SHOW_SEND_EMAILS_POPUP(state, payload) {
    state.showSendEmailsPopup = payload
  },
  SHOW_NOTIFICATION_SETTINGS(state, payload) {
    state.showNotificationSettingsPopup = payload
  },
  SHOW_ADMIN_NOTIFICATION_SETTINGS(state, payload) {
    state.showAdminNotificationSettingsPopup = payload
  },
  SHOW_END_PRICE_GOLD_DATE_POPUP(state, payload) {
    state.showEndPriceGoldDatePopup = payload
  },
  SET_END_PRICE_GOLD_DATE_OBJ(state, payload) {
    state.endPriceGoldDateObj = payload
  },
}

const getters = {}

export default {
  state,
  mutations,
  actions,
  getters
}
