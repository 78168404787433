<template>
  <div class="page-container">
    <PageHeader icon="card-active.svg" title="Управление картами" />
    <div class="page-content">
      <div class="tab-content table">
        <TableComponent
          :noData="noData"
          :columns="columns"
          :rows="tableRows_1"
          :totalCount="totalCount"
          :pageSize="pageSize"
          :pageCount="pageCount"
          :currentPage="Number(currentPage)"
          :isLoading="loading"
          :checkingOn="userRole !== 2"
          :title="'Топливные карты'"
          :isBordered="true"
          :show-additional-table-header="false"
          :isLightFontAdditional="true"
          :cardActions="true"
          @downloadXSLX="downloadXSLX"
          @regetData="regetData($event)"
          :actionType="'cardActions'"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import Tabs from '@/components/tabs.vue'
  import { mapActions, mapMutations, mapState } from 'vuex'
  import { filter } from 'core-js/internals/array-iteration'

  export default {
    name: 'CardsManagement',
    components: {
      Tabs
    },
    data() {
      return {
        tableColumns_1: [
          {
            name: 'ID',
            field: 'id',
            filter_name: 'card.id',
            filter_value: '',
            order: ''
          },
          {
            name: 'ВИНК',
            field: 'fuel_provider_id',
            filter_name: 'card.fuel_provider_id',
            filter_value: '',
            order: '',
            multiple: true
          },
          {
            name: 'Номер карты',
            field: 'number',
            filter_name: 'number',
            filter_value: '',
            order: '',
            multiple_search: true,
            search_items: []
          },
          {
            name: 'Дата изъятия',
            field: 'date_withdrawal',
            filter_name: 'date_withdrawal',
            filter_value: '',
            order: '',
            date_type: true
          },
          {
            name: 'Статус',
            field: 'status_name',
            filter_name: 'status.id',
            filter_value: '',
            order: ''
          },
          {
            name: 'Клиент',
            field: 'customer_name',
            filter_name: 'customer_user.id',
            filter_value: '',
            order: '',
            multiple: true
          },
          // {
          //   name: 'Водитель',
          //   field: 'driver'
          // },
          {
            name: 'Лимит, в сутки/месяц',
            field: 'activeLimits',
            filter_name: 'amount_limit',
            filter_name_from: 'amount_limit_from',
            filter_value_from: '',
            filter_name_to: 'amount_limit_to',
            filter_value_to: '',
            from_to_fields: true,
            order: '',
            card_limits: true
          },
          {
            name: 'Израсходовано\n' + 'лимита',
            field: 'activeLimits',
            filter_name: 'used_value',
            filter_name_from: 'used_value_from',
            filter_value_from: '',
            filter_name_to: 'used_value_to',
            filter_value_to: '',
            from_to_fields: true,
            order: '',
            used_limits: true
          },
          {
            name: 'Интеграция',
            field: 'integration_name',
            filter_name: 'card.integration_id',
            filter_value: '',
            order: ''
          },
          // {
          //   name: 'Лимит, р',
          //   field: 'amount_limit',
          //   filter_name: 'amount_limit',
          //   filter_name_from: 'amount_limit_from',
          //   filter_value_from: '',
          //   filter_name_to: 'amount_limit_to',
          //   filter_value_to: '',
          //   from_to_fields: true,
          //   order: ''
          // },
          // {
          //   name: 'Лимит, л',
          //   field: 'volume_limit',
          //   filter_name: 'volume_limit',
          //   filter_name_from: 'volume_limit_from',
          //   filter_value_from: '',
          //   filter_name_to: 'volume_limit_to',
          //   filter_value_to: '',
          //   from_to_fields: true,
          //   order: ''
          // },
          // {
          //   name: 'Время лимита',
          //   field: 'limit_id',
          //   filter_name: 'limit_id',
          //   filter_value: '',
          //   order: ''
          // },
          {
            name: '',
            field: 'actions'
          }
        ],
        tableRows_1: [],
        noData: false,
        loading: false,
        totalCount: 0,
        pageSize: 0,
        pageCount: 0,
        currentPage: 0
      }
    },
    watch: {
      async cards(val) {
        await this.setTablesData()
      }
    },
    computed: {
      ...mapState({
        token: (state) => state.user.token,
        cards: (state) => state.admin.cards,
        integrations: (state) => state.admin.integrations,
        cardStatuses: (state) => state.admin.cardStatuses,
        userRole: (state) => state.user.userRole,
        columns: (state) => state.popups.columns,
        actionPopup: (state) => state.popups.actionPopup,
        paginationFilters: (state) => state.popups.paginationFilters,
        cardLimits: (state) => state.user.card_limits
      }),
      tabs() {
        switch (this.userRole) {
          case 3:
            return [this.tabsNames[0], this.tabsNames[1]]
          case 2:
            return [this.tabsNames[0], this.tabsNames[2], this.tabsNames[3]]
        }
      }
    },
    methods: {
      ...mapActions({
        getCards: 'getCards',
        getCardStatuses: 'getCardStatuses',
        downloadXLSXFile: 'downloadXLSXFile',
        getCardLimits: 'getCardLimits',
        getFuelTypesReq: 'getFuelTypes',
        getFuelProviders: 'getFuelProviders',
        getIntegrations: 'getIntegrations'
      }),
      ...mapMutations({
        setColumns: 'SET_COLUMNS',
        setColumns_2: 'SET_COLUMNS_2',
        setPaginationFilters: 'SET_PAGINATION_FILTERS',
        resetPaginationFilters: 'RESET_PAGINATION_FILTERS'
      }),
      async regetData(data) {
        console.log(data)
        this.loading = true

        let args = [
          {
            name: 'page',
            value: data.pageNumber
          },
          {
            name: 'perPage',
            value: data.size
          }
        ]

        if (Math.ceil(this.totalCount / data.size) < data.pageNumber) {
          args[0].value = 1
        }

        this.setPaginationFilters(args)

        await this.getCards()
      },
      async downloadXSLX() {
        this.$store.commit('SET_DOWNLOAD_LOADING', true)
        await this.getCards(true)
        this.$store.commit('SET_DOWNLOAD_LOADING', false)
      },
      async setTablesData() {
        this.totalCount = this.cards.total_count
        this.pageSize = this.cards.page_size
        this.pageCount = this.cards.page_count
        this.currentPage = this.cards.current_page
        let cardsData = []

        for (let i = 0; i < this.cards.data.length; i++) {
          let card = this.cards.data[i]
          card.order_number = i + 1
          card.brand = /*card.cardProvider?.name || */ card.brand
          card.status_name = card.status?.name
          card.customer_name = card.customer?.user?.name
          // let date = card.expired_at ? new Date(card.expired_at).toLocaleDateString() : null
          // card.expired_at_date = date
          if (card.expired_at) {
            card.expired_at = card.expired_at.split(' ')[0]
          }
          card.checked = false
          card.show_all = false
          //card.driver = '???????'

          card.actionType = 'cardActions'

          if (card.status_name === 'Активен') {
            card.green = { status_name: true }
          } else {
            card.red = { status_name: true }
          }

          cardsData.push(card)
        }

        this.tableRows_1 = cardsData

        if (!this.tableRows_1 || this.tableRows_1.length === 0) {
          this.noData = true
        } else {
          this.noData = false
        }

        if (this.columns) {
          this.setColumns(this.columns)
        } else {
          if (this.userRole !== 1) {
            this.tableColumns_1 = this.tableColumns_1.filter((item) => item.field !== 'actions')
          }
          this.setColumns(this.tableColumns_1)
        }

        this.loading = false
      }
    },
    async mounted() {
      this.setColumns(null)
      this.setColumns_2(null)
      this.resetPaginationFilters()
      // setTimeout(() => {
      //   this.setColumns(this.tableColumns_1)
      // }, 1800)

      await this.getCardLimits()
      await this.getCards()
      await this.getCardStatuses()
      await this.getFuelTypesReq()
      await this.getFuelProviders()
      await this.getIntegrations()

      //await this.setTablesData()
    }
  }
</script>

<style scoped lang="scss">
  @import '../../styles/variables';
  .separator {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    height: 1px;
    background: $back;
    width: 100%;
  }
  .tab-content {
    margin-top: 30px;
  }
</style>
