<template>
  <mainMenu/>
</template>

<style lang="scss">
#app {
  font-family: SF_Pro_Medium, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
<script>
import {mapActions, mapMutations, mapState} from "vuex";
import mainMenu from "./components/menu.vue";
import {popups} from "./mixins/popups";
export default {
  name: 'App',
  components: {
    mainMenu,
  },
  mixins: [popups],
  computed: {
    ...mapState({
      token: state => state.user.token,
      cardsReportFilters: (state) => state.popups.cardsReportFilters,
    }),
  },
  watch: {
    $route(val) {
      this.setDateRangeStore('')
      let filter = [...this.cardsReportFilters]
      filter.forEach(item => {
        item.value = 0;
      })
      this.setCardsReportFilters(filter)
    }
  },
  methods: {
    ...mapMutations({
      setToken: 'SET_TOKEN',
      setDateRangeStore: 'SET_DATE_RANGE',
      setCardsReportFilters: 'SET_CARDS_REPORT_FILTERS',
    }),
  },
}
</script>
<script setup>
</script>
