<template>
  <div class="page-container">
    <PageHeader icon="dashboard-active.svg" title="Дашборд" />
    <div class="page-content column">
      <tabs :tabsNames="tabsNames" @firstTabActive="firstTabActive = $event" />
      <div class="tab-content table" :class="{ 'tab-content__active-first': firstTabActive }">
        <TableComponent
          :columns="columns"
          :rows="tableRows_1"
          :title="'Клиенты'"
          :isBordered="true"
          :totalCount="totalCount"
          :additionalRows="additionalRows_1"
          :show-additional-table-header="false"
          :isLightFontAdditional="true"
          :pageSize="pageSize"
          :pageCount="pageCount"
          :currentPage="Number(currentPage)"
          :isLoading="loading"
          @downloadXSLX="downloadXSLX"
          @regetData="regetData($event)"
          :noData="noData"
          :actionType="'managerDashboardActions'"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapMutations, mapState } from 'vuex'
  import Tabs from '@/components/tabs.vue'

  export default {
    name: 'ExecutiveDashboard',
    components: { Tabs },
    data: () => ({
      firstTabActive: true,
      tabsNames: [
        { name: 'Дашборд руководителя', linkName: 'admin.dashboard.executive' },
        { name: 'Дашборд бухгалтера', linkName: 'admin.dashboard.accountant' },
        { name: 'Оперативный отчет', linkName: 'admin.dashboard.operative' }
      ],
      tableColumns_1: [
        {
          name: 'Наименование клиента',
          field: 'client_name',
          filter_name: 'users_ids[]',
          filter_value: '',
          order: '',
          multiple: true
        },
        {
          name: 'Текущий\n' + 'баланс, р.',
          field: 'current_balance_format',
          fieldDefault: 'current_balance',
          filter_name: 'current_balance',
          filter_value: '',
          filter_name_from: 'current_balance_from',
          filter_value_from: '',
          filter_name_to: 'current_balance_to',
          filter_value_to: '',
          from_to_fields: true,
          order: '',
          balance_field: true
        },
        {
          name: 'Потребление\n' + 'за текущий месяц\n' + '(тип топлива n), л.',
          field: 'fuel_type_month',
          filter_name: 'fuel_type_month_ids[]',
          filter_value: '',
          order: '',
          fuel_type: true,
          multiple: true
        },
        {
          name: 'Оборот\n' + 'за текущий\n' + 'год, р.',
          field: 'turnover_current_year',
          filter_name: 'turnover_current_year',
          filter_value: '',
          filter_name_from: 'turnover_current_year_from',
          filter_value_from: '',
          filter_name_to: 'turnover_current_year_to',
          filter_value_to: '',
          from_to_fields: true,
          order: ''
        },
        {
          name: 'Потребление\n' + 'за текущий год\n' + '(тип топлива n), л.',
          field: 'fuel_type_year',
          filter_name: 'fuel_type_year_ids[]',
          filter_value: '',
          order: '',
          fuel_type: true,
          multiple: true
        },
        {
          name: 'Менеджер',
          field: 'manager',
          filter_name: 'manager_ids[]',
          filter_value: '',
          order: '',
          multiple: true
        }
      ],
      tableRows_1: [],
      additionalRows_1: [
        {
          client_name: 'Итого',
          current_balance: ``,
          fuel_type_month: ``,
          turnover_current_year: ``,
          fuel_type_year: ``,
          manager: ``
        }
      ],
      noData: false,
      loading: false,
      totalCount: 0,
      pageSize: 0,
      pageCount: 0,
      currentPage: 0
    }),
    watch: {
      async manager_dashboard(val) {
        await this.setTablesData()
      }
    },
    computed: {
      ...mapState({
        userRole: (state) => state.user.userRole,
        columns: (state) => state.popups.columns,
        manager_dashboard: (state) => state.admin.manager_dashboard,
        operations_report: (state) => state.admin.operations_report
      })
    },
    methods: {
      ...mapActions({
        getManagerDashboard: 'getManagerDashboard',
        getOperationsReport: 'getOperationsReport',
        downloadXLSXFile: 'downloadXLSXFile',
        getFuelTypesReq: 'getFuelTypes'
      }),
      ...mapMutations({
        setColumns: 'SET_COLUMNS',
        setPaginationFilters: 'SET_PAGINATION_FILTERS',
        resetPaginationFilters: 'RESET_PAGINATION_FILTERS'
      }),
      setFormatPrice(val) {
        let p;
        if (String(val).includes('.')) {
          p = val.split(".");
          return p[0].split("").reverse().reduce(function(acc, val, i, orig) {
            return val + (val !== "-" && i && !(i % 3) ? " " : "") + acc;
          }, "") + '.' + p[1];
        } else {
          p = String(val)
          return p.split("").reverse().reduce(function(acc, val, i, orig) {
            return val + (val !== "-" && i && !(i % 3) ? " " : "") + acc;
          }, "");
        }
      },
      async regetData(data) {
        console.log(data)
        this.loading = true

        let args = [
          {
            name: 'page',
            value: data.pageNumber
          },
          {
            name: 'page_size',
            value: data.size
          }
        ]

        if (Math.ceil(this.totalCount / data.size) < data.pageNumber) {
          args[0].value = 1
        }

        this.setPaginationFilters(args)

        await this.getManagerDashboard()
      },
      async downloadXSLX() {
        this.$store.commit('SET_DOWNLOAD_LOADING', true)
        await this.getManagerDashboard(true)
        this.$store.commit('SET_DOWNLOAD_LOADING', false)
      },
      async setTablesData() {
        this.totalCount = this.manager_dashboard.total_count
        this.pageSize = this.manager_dashboard.page_size
        this.pageCount = this.manager_dashboard.page_count
        this.currentPage = this.manager_dashboard.current_page
        let dashboardData = []

        for (let i = 0; i < this.manager_dashboard.data.length; i++) {
          let data = this.manager_dashboard.data[i]
          data.actionType = 'managerDashboardActions'
          data.current_balance_format = this.setFormatPrice(Number(data.current_balance).toFixed(2))
          data.turnover_current_year = this.setFormatPrice(Number(data.turnover_current_year).toFixed(2))

          dashboardData.push(data)
        }

        this.tableRows_1 = dashboardData

        this.additionalRows_1[0].fuel_type_month = this.setFormatPrice(this.manager_dashboard.total.total_fuel_type_month.toFixed(2))
        this.additionalRows_1[0].turnover_current_year = this.setFormatPrice(this.manager_dashboard.total.total_turnover_current_year.toFixed(2))
        this.additionalRows_1[0].fuel_type_year = this.setFormatPrice(this.manager_dashboard.total.total_fuel_type_year.toFixed(2))

        if (!this.tableRows_1 || this.tableRows_1.length === 0) {
          this.noData = true
        } else {
          this.noData = false
        }

        if (this.columns) {
          this.setColumns(this.columns)
        } else {
          this.setColumns(this.tableColumns_1)
        }

        this.loading = false
      }
    },
    async mounted() {
      this.setColumns(null)

      // this.resetPaginationFilters()

      await this.getFuelTypesReq()

      // await this.getManagerDashboard()
      //
      // await this.getOperationsReport()

      let data = {
        size: 100,
        pageNumber: 1
      }

      await this.regetData(data)
    }
  }
</script>

<style scoped lang="scss">
  @import '../../styles/variables';

  .page-content {
    display: flex;

    &.column {
      flex-direction: column;
    }
  }

  .tab-content {
    &.offset {
      margin-top: 30px;
    }
  }
</style>
