<template>
  <div class="page-container">
    <PageHeader icon="dashboard-active.svg" title="Оперативный отчет" />
    <div class="page-content column">
      <tabs :tabsNames="tabsNames" @firstTabActive="firstTabActive = $event" />
      <div class="tab-content table">
        <TableComponent
          :columns="columns_2"
          :noData="noData_2"
          :rows="tableRows_2"
          :additionalRows="additionalRows_1"
          :show-additional-table-header="false"
          :isLightFontAdditional="true"
          :title="`Оперативный отчет`"
          :isBordered="true"
          :isSecondTable="true"
          :totalCount="totalCount_2"
          :pageSize="pageSize_2"
          :pageCount="pageCount_2"
          :currentPage="Number(currentPage_2)"
          :isLoading="loading_2"
          @downloadXSLX="downloadXSLX_2"
          @regetData="regetData_2($event)"
          :actionType="'managerDashboardOperativeReportActions'"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapMutations, mapState } from 'vuex'
  import Tabs from '@/components/tabs.vue'
  import TableComponent from "@/components/table.vue";

  export default {
    name: 'OperationsDashboard',
    components: {TableComponent, Tabs },
    data: () => ({
      firstTabActive: true,
      tabsNames: [
        { name: 'Дашборд руководителя', linkName: 'admin.dashboard.executive' },
        { name: 'Дашборд бухгалтера', linkName: 'admin.dashboard.accountant' },
        { name: 'Оперативный отчет', linkName: 'admin.dashboard.operative' }
      ],
      tableColumns_2: [
        {
          name: 'Наименование клиента',
          field: 'organization_name',
          filter_name: 'customer_ids[]',
          filter_value: '',
          order: '',
          multiple: true
        },
        {
          name: 'Сальдо\n' + 'на начало\n' + 'года, р.',
          field: 'saldo_beginning_of_year',
          filter_name: 'saldo_beginning_of_year',
          filter_value: '',
          order: '',
          filter_name_from: 'saldo_beginning_of_year_from',
          filter_value_from: '',
          filter_name_to: 'saldo_beginning_of_year_to',
          filter_value_to: '',
          from_to_fields: true
        },
        {
          name: 'Оборот за каждый\n' + 'месяц в текущем\n' + 'году, р.',
          field: 'turnover_by_month',
          filter_name: 'turnover_by_month',
          filter_value: '',
          order: '',
          month_arr: true
        },
        {
          name: 'Сумму задолженности\n' + 'за каждый месяц\n' + 'в текущем году, р.',
          field: 'debt_by_month',
          filter_name: 'debt_by_month',
          filter_value: '',
          order: '',
          month_arr: true
        },
        {
          name: 'Сумму оплат\n' + 'за текущий год, р.',
          field: 'total_payments_current_year',
          filter_name: 'total_payments_current_year',
          filter_value: '',
          order: '',
          filter_name_from: 'total_payments_current_year_from',
          filter_value_from: '',
          filter_name_to: 'total_payments_current_year_to',
          filter_value_to: '',
          from_to_fields: true
        },
        {
          name: 'Текущий баланс, р.',
          field: 'current_balance_format',
          fieldDefault: 'current_balance',
          filter_name: 'current_balance',
          filter_value: '',
          order: '',
          filter_name_from: 'current_balance_from',
          filter_value_from: '',
          filter_name_to: 'current_balance_to',
          filter_value_to: '',
          from_to_fields: true,
          balance_field: true
        },
        {
          name: 'Менеджер',
          field: 'manager',
          filter_name: 'manager_ids[]',
          filter_value: '',
          order: '',
          multiple: true
        }
      ],
      tableRows_2: [],
      additionalRows_1: [
        {
          organization_name: 'Итого',
          saldo_beginning_of_year: '',
          turnover_by_month: '',
          debt_by_month: '',
          total_payments_current_year: '',
          current_balance_format: '',
          manager: ''
        }
      ],

      noData_2: false,
      loading_2: false,
      totalCount_2: 0,
      pageSize_2: 0,
      pageCount_2: 0,
      currentPage_2: 0
    }),
    watch: {
      async operations_report(val) {
        await this.setTablesData_2()
      }
    },
    computed: {
      ...mapState({
        userRole: (state) => state.user.userRole,
        columns_2: (state) => state.popups.columns_2,
        operations_report: (state) => state.admin.operations_report
      })
    },
    methods: {
      ...mapActions({
        getManagerDashboard: 'getManagerDashboard',
        getOperationsReport: 'getOperationsReport',
        downloadXLSXFile: 'downloadXLSXFile',
        getFuelTypesReq: 'getFuelTypes'
      }),
      ...mapMutations({
        setColumns_2: 'SET_COLUMNS_2',
        setPaginationFilters_2: 'SET_PAGINATION_FILTERS_2',
        resetPaginationFilters_2: 'RESET_PAGINATION_FILTERS_2'
      }),

      setFormatPrice(val) {
        let p;
        if (String(val).includes('.')) {
          p = val.split(".");
          return p[0].split("").reverse().reduce(function(acc, val, i, orig) {
            return val + (val !== "-" && i && !(i % 3) ? " " : "") + acc;
          }, "") + '.' + p[1];
        } else {
          p = String(val)
          return p.split("").reverse().reduce(function(acc, val, i, orig) {
            return val + (val !== "-" && i && !(i % 3) ? " " : "") + acc;
          }, "");
        }
      },
      async regetData_2(data) {
        console.log(data)
        this.loading_2 = true

        let args = [
          {
            name: 'page',
            value: data.pageNumber
          },
          {
            name: 'page_size',
            value: data.size
          }
        ]

        if (Math.ceil(this.totalCount_2 / data.size) < data.pageNumber) {
          args[0].value = 1
        }

        this.setPaginationFilters_2(args)

        await this.getOperationsReport()
      },
      async downloadXSLX_2() {
        this.$store.commit('SET_DOWNLOAD_LOADING', true)
        await this.getOperationsReport(true)
        this.$store.commit('SET_DOWNLOAD_LOADING', false)
      },
      async setTablesData_2() {
        this.totalCount_2 = this.operations_report.total_count
        this.pageSize_2 = this.operations_report.page_size
        this.pageCount_2 = this.operations_report.page_count
        this.currentPage_2 = this.operations_report.current_page
        let dashboardData = []

        // let operations_report_data = Object.keys(this.operations_report.data).map((key) => this.operations_report.data[key]);
        // this.operations_report.data.forEach(item => {
        //   operations_report_data.push(item)
        // })
        for (let i = 0; i < this.operations_report.data.length; i++) {
          let data = this.operations_report.data[i]
          data.actionType = 'managerDashboardOperativeReportActions'
          data['turnover_by_month_show_all'] = false
          data['debt_by_month_show_all'] = false
          data.saldo_beginning_of_year = this.setFormatPrice(Number(data.saldo_beginning_of_year).toFixed(2))
          data.total_payments_current_year = this.setFormatPrice(Number(data.total_payments_current_year).toFixed(2))
          data.current_balance_format = this.setFormatPrice(Number(data.current_balance).toFixed(2))

          dashboardData.push(data)
        }

        this.tableRows_2 = dashboardData

        this.additionalRows_1[0].saldo_beginning_of_year = this.setFormatPrice(this.operations_report.total.total_saldo.toFixed(2))
        this.additionalRows_1[0].turnover_by_month = this.setFormatPrice(this.operations_report.total.total_turnover_by_month.toFixed(2))
        this.additionalRows_1[0].debt_by_month = this.setFormatPrice(this.operations_report.total.total_debt_by_month.toFixed(2))
        this.additionalRows_1[0].total_payments_current_year = this.setFormatPrice(this.operations_report.total.total_total_payments_current_year.toFixed(2))
        this.additionalRows_1[0].current_balance_format = this.setFormatPrice(this.operations_report.total.total_current_balance.toFixed(2))

        if (!this.tableRows_2 || this.tableRows_2.length === 0) {
          this.noData_2 = true
        } else {
          this.noData_2 = false
        }

        if (this.columns_2) {
          this.setColumns_2(this.columns_2)
        } else {
          this.setColumns_2(this.tableColumns_2)
        }

        this.loading_2 = false
      }
    },
    async mounted() {
      this.setColumns_2(null)

      await this.getFuelTypesReq()

      let data = {
        size: 100,
        pageNumber: 1
      }

      await this.regetData_2(data)
    }
  }
</script>

<style scoped lang="scss">
  @import '../../styles/variables';

  .page-content {
    display: flex;

    &.column {
      flex-direction: column;
    }
  }

  .tab-content {
    &.offset {
      margin-top: 30px;
    }
  }
</style>
