<template>
  <div class="popup small">
    <Loader v-if="loading" />
    <h4 class="popup__title middle">Удаление файла</h4>

    <span class="popup__subtitle">
      Вы точно желаете удалить файл "<span>{{file.filename}}</span>"?
    </span>

    <div class="actions">
      <button class="button btn-primary" @click="closeAll()">Отмена</button>
      <button class="button btn-primary" @click="deleteFile()">Удалить</button>
    </div>
  </div>
</template>

<script>
import {popups} from '@/mixins/popups'
import {mapActions, mapState} from "vuex";
import Loader from '@/components/common/Loader.vue'

export default {
  name: "removeFilePopup",
  mixins: [popups],
  components: {
    Loader
  },
  data: () => ({
    loading: false
  }),
  computed: {
    ...mapState({
      file: state => state.popups.file,
    })
  },
  methods: {
    ...mapActions({
      deletePriceFile: 'deletePriceFile',
      deletePriceGoldFile: 'deletePriceGoldFile',
      deletePricePartnerFile: 'deletePricePartnerFile',
      deleteTxFile: 'deleteTxFile',
    }),
    async deleteFile() {
      this.loading = true
      console.log(this.file)

      if (this.file.actionType === 'uploadPricesFiles') {
        if (this.$route.name === 'files_upload.prices_gold') {
          await this.deletePriceGoldFile(this.file.id)
        }
        if (this.$route.name === 'files_upload.prices_partner') {
          await this.deletePricePartnerFile(this.file.id)
        }
        if (this.$route.name === 'files_upload.prices') {
          await this.deletePriceFile(this.file.id)
        }
      } else if (this.file.actionType === 'uploadTxFiles') {
        await this.deleteTxFile(this.file.id)
      }

      this.loading = false
      //this.closeAll()
    },
  },
  mounted() {}
}
</script>

<style scoped lang="scss">
@import "../../styles/variables";

.popup {
  :deep(.loader) {
    height: 200%;
    width: 200%;
    left: -50%;
    top: -50%;
    border-radius: 25px;
    transform: scale(0.5);
    svg {
      position: unset;
    }
  }
}
</style>