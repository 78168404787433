<template>
  <div class="page-container">
    <PageHeader icon="clients-active.svg" title="Управление клиентами" />
    <div class="page-content">
      <div class="tab-content table" v-if="userRole > 0">
        <TableComponent
          v-if="userRole === 1"
          :noData="noData"
          :columns="columns"
          :rows="tableRows_1"
          :totalCount="totalCount"
          :pageSize="pageSize"
          :pageCount="pageCount"
          :currentPage="Number(currentPage)"
          :isLoading="loading"
          :title="'Клиенты'"
          :isBordered="true"
          :show-additional-table-header="false"
          :isLightFontAdditional="true"
          @regetData="regetData($event)"
          @downloadXSLX="downloadXSLX"
          :actionType="'clientsManagement'"
          :showSetBlockDayBtn="true"
          :showSendEmailBtn="true"
        />
        <TableComponent
          v-else
          :noData="noData"
          :columns="columns_2"
          :rows="tableRows_2"
          :datePicker="true"
          :isSecondTable="true"
          :totalCount="totalCount"
          :pageSize="pageSize"
          :pageCount="pageCount"
          :currentPage="Number(currentPage)"
          :isLoading="loading"
          :title="'Клиенты'"
          :isBordered="true"
          :show-additional-table-header="false"
          :isLightFontAdditional="true"
          @regetData="regetData($event)"
          @downloadXSLX="downloadXSLX"
          :actionType="'clientsManagement'"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import Tabs from '@/components/tabs.vue'
  import { mapActions, mapMutations, mapState } from 'vuex'

  export default {
    name: 'ClientManagement',
    components: {
      Tabs
    },
    data() {
      return {
        tableColumns_1: [
          {
            name: 'ID',
            field: 'id',
            filter_name: 'customer.id',
            filter_value: '',
            order: ''
          },
          {
            name: 'Имя или название организации',
            field: 'employee',
            filter_name: 'employee',
            filter_value: '',
            order: '',
            multiple_search: true,
            search_items: []
          },
          {
            name: 'Кол-во карт',
            field: 'cards_count',
            filter_name: 'cards_count',
            filter_name_from: 'cards_count_from',
            filter_value_from: '',
            filter_name_to: 'cards_count_to',
            filter_value_to: '',
            from_to_fields: true,
            order: '',
            cards_field: true
          },
          {
            name: 'Email',
            field: 'email',
            filter_name: 'customer_user.login',
            filter_value: '',
            order: ''
          },
          {
            name: 'Статус',
            field: 'status_name',
            filter_name: 'customer_user.status_id',
            filter_value: '',
            order: ''
          },
          {
            name: 'Баланс',
            field: 'balance_format',
            fieldDefault: 'balance',
            filter_name: 'balance',
            filter_name_from: 'balance_from',
            filter_value_from: '',
            filter_name_to: 'balance_to',
            filter_value_to: '',
            from_to_fields: true,
            order: '',
            balance_field: true
          },
          {
            name: 'Менеджер',
            field: 'manager_name',
            filter_name: 'manager_user.id',
            filter_value: '',
            order: '',
            multiple: true
          },
          // {
          //   name: 'Скидка',
          //   field: 'discount',
          //   filter_name: 'discount',
          //   filter_name_from: 'discount_from',
          //   filter_value_from: '',
          //   filter_name_to: 'discount_to',
          //   filter_value_to: '',
          //   from_to_fields: true,
          //   order: ''
          // },
          {
            name: 'Кредитный лимит',
            field: 'limit',
            filter_name: 'customer.limit',
            filter_name_from: 'customer.limit_from',
            filter_value_from: '',
            filter_name_to: 'customer.limit_to',
            filter_value_to: '',
            from_to_fields: true,
            order: ''
          },
          {
            name: '',
            field: 'actions'
          }
        ],
        tableRows_1: [
          {
            id: '1',
            organization_name: 'ООО “Юнион Лаб”',
            cards_quantity: '9',
            email: 'ivankovich@undsgn.ru',
            status: 'Активен',
            balance: '7 175 000,00',
            manager: 'Петров Э. В.',
            discount: '500',
            credit_limit: '50 000',
            green: { status: true },
            actionType: 'clientsManagement'
          },
          {
            id: '2',
            organization_name: 'ООО “ААА”',
            cards_quantity: '9',
            email: 'some@mail.ru',
            status: 'Активен',
            balance: '175 000,00',
            manager: 'Сидоров Э. В.',
            discount: '1 200',
            credit_limit: '150 000',
            green: { status: true },
            actionType: 'clientsManagement'
          }
        ],
        tableColumns_2: [
          {
            name: 'ID',
            field: 'id',
            filter_name: 'customer.id',
            filter_value: '',
            order: ''
          },
          {
            name: 'Имя или название организации',
            field: 'employee',
            filter_name: 'employee',
            filter_value: '',
            order: '',
            multiple_search: true,
            search_items: []
          },
          {
            name: 'Кол-во карт',
            field: 'cards_count',
            filter_name: 'cards_count',
            filter_value: '',
            order: '',
            cards_field: true,
            filter_name_from: 'cards_count_from',
            filter_value_from: '',
            filter_name_to: 'cards_count_to',
            filter_value_to: '',
            from_to_fields: true
          },
          {
            name: 'Email',
            field: 'email',
            filter_name: 'customer_user.login',
            filter_value: '',
            order: ''
          },
          {
            name: 'Статус',
            field: 'status_name',
            filter_name: 'customer_user.status_id',
            filter_value: '',
            order: ''
          },
          {
            name: 'Баланс',
            field: 'balance_format',
            fieldDefault: 'balance',
            filter_name: 'balance',
            filter_value: '',
            order: '',
            balance_field: true,
            filter_name_from: 'balance_from',
            filter_value_from: '',
            filter_name_to: 'balance_to',
            filter_value_to: '',
            from_to_fields: true
          },
          {
            name: 'Потребление\n' + 'за текущий месяц\n' + '(тип топлива n)',
            field: 'transactions_month_summary_stringify',
            filter_name: 'fuel_type_month',
            filter_value: '',
            order: '',
            fuel_type: true,
            multiple: true
          },
          {
            name: 'Потребление\n' + 'за текущий год\n' + '(тип топлива n)',
            field: 'transactions_year_summary_stringify',
            filter_name: 'fuel_type_year',
            filter_value: '',
            order: '',
            fuel_type: true,
            multiple: true
          }
        ],
        tableRows_2: [
          {
            organization_name: 'ООО “Юнион Лаб”',
            cards_quantity: '9',
            email: 'ivankovich@undsgn.ru',
            status: 'Активен',
            balance: '7 175 000,00',
            year_strait: '500',
            month_strait: '50',
            total_fuel_consumption: '5000',
            green: { status: true, balance: true }
            //class: 'CLASS'
          },
          {
            organization_name: 'ООО “Юнион Лаб”',
            cards_quantity: '9',
            email: 'ivankovich@undsgn.ru',
            status: 'Активен',
            balance: '-7 175 000,00',
            year_strait: '500',
            month_strait: '50',
            total_fuel_consumption: '5000',
            green: { status: true },
            red: { balance: true }
          }
        ],
        noData: false,
        loading: false,
        totalCount: 0,
        pageSize: 0,
        pageCount: 0,
        currentPage: 0
      }
    },
    watch: {
      async customers(val) {
        await this.setTablesData()
      }
    },
    computed: {
      ...mapState({
        token: (state) => state.user.token,
        user_statuses: (state) => state.user.user_statuses,
        userRole: (state) => state.user.userRole,
        columns: (state) => state.popups.columns,
        columns_2: (state) => state.popups.columns_2,
        customers: (state) => state.admin.customers
      }),
      tabs() {
        switch (this.userRole) {
          case 3:
            return [this.tabsNames[0], this.tabsNames[1]]
          case 2:
            return [this.tabsNames[0], this.tabsNames[2], this.tabsNames[3]]
        }
      }
    },
    methods: {
      ...mapActions({
        getCustomers: 'getCustomers',
        downloadXLSXFile: 'downloadXLSXFile',
        getUserStatuses: 'getUserStatuses',
        getFuelTypesReq: 'getFuelTypes',
        getFuelProviders: 'getFuelProviders',
        getIntegrations: 'getIntegrations',
        getPaymentIntegrations: 'getPaymentIntegrations',
        getRegions: 'getRegions',
        getDiscountBlockDay: 'getDiscountBlockDay'
      }),
      ...mapMutations({
        setColumns: 'SET_COLUMNS',
        setColumns_2: 'SET_COLUMNS_2',
        setPaginationFilters: 'SET_PAGINATION_FILTERS',
        setPaginationFilters_2: 'SET_PAGINATION_FILTERS_2',
        resetPaginationFilters: 'RESET_PAGINATION_FILTERS',
        resetPaginationFilters_2: 'RESET_PAGINATION_FILTERS_2'
      }),
      setFormatPrice(val) {
        let p;
        if (String(val).includes('.')) {
          p = val.split(".");
          return p[0].split("").reverse().reduce(function(acc, val, i, orig) {
            return val + (val !== "-" && i && !(i % 3) ? " " : "") + acc;
          }, "") + '.' + p[1];
        } else {
          p = String(val)
          return p.split("").reverse().reduce(function(acc, val, i, orig) {
            return val + (val !== "-" && i && !(i % 3) ? " " : "") + acc;
          }, "");
        }
      },
      async downloadXSLX() {
        this.$store.commit('SET_DOWNLOAD_LOADING', true)
        await this.getCustomers(true)
        this.$store.commit('SET_DOWNLOAD_LOADING', false)
      },
      async regetData(data) {
        console.log(data)
        this.loading = true

        let args = [
          {
            name: 'page',
            value: data.pageNumber
          },
          {
            name: 'perPage',
            value: data.size
          }
        ]

        if (Math.ceil(this.totalCount / data.size) < data.pageNumber) {
          args[0].value = 1
        }

        this.setPaginationFilters(args)
        this.setPaginationFilters_2(args)

        await this.getCustomers()
      },
      async setTablesData() {
        await this.$nextTick()
        this.totalCount = this.customers.total_count
        this.pageSize = this.customers.page_size
        this.pageCount = this.customers.page_count
        this.currentPage = this.customers.current_page
        let customersData = []

        for (let i = 0; i < this.customers.data.length; i++) {
          let customer = this.customers.data[i]
          customer.name = customer.user?.name
          customer.email = customer.user?.login
          customer.manager_name = customer.manager?.user?.name
          customer.balance_format = this.setFormatPrice(Number(customer.balance).toFixed(2))
          customer.limit = this.setFormatPrice(Number(customer.limit).toFixed(2))

          let status_name = this.user_statuses.find((item) => item.id === customer.user?.status_id)

          if (status_name) {
            customer.status_name = status_name.name
          }

          customer.actionType = 'clientsManagement'

          if (customer.status_name === 'Активен') {
            customer.green = { status_name: true }
          } else {
            customer.red = { status_name: true }
          }

          customersData.push(customer)
        }

        this.tableRows_1 = customersData
        this.tableRows_2 = customersData

        if (!this.tableRows_1 || this.tableRows_1.length === 0) {
          this.noData = true
        } else {
          this.noData = false
        }
        if (!this.tableRows_2 || this.tableRows_2.length === 0) {
          this.noData = true
        } else {
          this.noData = false
        }

        if (this.columns) {
          this.setColumns(this.columns)
        } else {
          this.setColumns(this.tableColumns_1)
        }

        if (this.columns_2) {
          this.setColumns_2(this.columns_2)
        } else {
          this.setColumns_2(this.tableColumns_2)
        }

        this.loading = false
      }
    },
    async mounted() {
      this.setColumns(null)
      this.setColumns_2(null)
      this.resetPaginationFilters()
      this.resetPaginationFilters_2()
      // setTimeout(() => {
      //   this.setColumns(this.tableColumns_1)
      //   this.setColumns_2(this.tableColumns_2)
      // }, 1800)

      if (!this.user_statuses || this.user_statuses.length === 0) {
        await this.getUserStatuses()
      }

      await this.getFuelTypesReq()
      await this.getFuelProviders()
      await this.getRegions()
      await this.getIntegrations()
      await this.getPaymentIntegrations()

      await this.getCustomers()
      if (this.userRole === 1) {
        await this.getDiscountBlockDay()
      }
    }
  }
</script>

<style scoped lang="scss">
  @import '../styles/variables';
  .separator {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    height: 1px;
    background: $back;
    width: 100%;
  }
  .tab-content {
    margin-top: 30px;
  }
</style>
