<template>
  <div class="popup">
    <Loader v-if="loading" />

    <h4 class="popup__title">Редактирование клиента</h4>

    <template v-if="clientEdit">
      <clientForm
          :client="clientEdit"
          :edit="true"
      />
    </template>
    <div class="form fake">
      <div class="left"></div>
      <div class="right">
        <div class="actions">
          <button class="button btn-primary" @click="closeAll()">Отмена</button>
          <button class="button btn-primary" :disabled="disabledSubmitButton" @click="editClient()">Сохранить</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {popups} from '@/mixins/popups'
import {toFormData} from '@/utils/toFormData'
import vSelect from 'vue-select'
import Loader from '@/components/common/Loader.vue'
import 'vue-select/dist/vue-select.css';
import {mapActions, mapState} from "vuex";
import Payments from "@/Pages/Payments.vue";
import VueDatePicker from "@vuepic/vue-datepicker";
import clientForm from "@/components/clientForm.vue";
export default {
  name: "editClientPopup",
  mixins: [popups],
  components: {
    clientForm,
    VueDatePicker,
    Payments,
    vSelect,
    Loader
  },
  data: () => ({
    loading: false,
    clientEdit: null
  }),
  computed: {
    ...mapState({
      client: state => state.popups.client,
      surety_files_to_remove: state => state.admin.surety_files_to_remove,
      surety_files_to_add: state => state.admin.surety_files_to_add,
      supply_files_to_remove: state => state.admin.supply_files_to_remove,
      supply_files_to_add: state => state.admin.supply_files_to_add,
      user_statuses: state => state.user.user_statuses,
      managers: state => state.admin.managers,
    }),
    managerOptions() {
      return this.managers
    },
    disabledSubmitButton() {
      if (this.clientEdit) {
        return !this.clientEdit.employee || !this.clientEdit.name || !this.clientEdit.login || !this.clientEdit.manager || !this.clientEdit.integration_id || !this.clientEdit.payment_integration_id
      }
      return true;
    }
  },
  methods: {
    ...mapActions({
      editCustomer: 'editCustomer',
      getUsersSearch: 'getUsersSearch',
      getCustomerById: 'getCustomerById',
    }),
    async fetchOptions (search, loading) {
      if (search.length >= 3) {
        console.log(search)
        loading(true)

        await this.getUsersSearch({
          search,
          type: 2
        })
        loading(false)
      }
    },
    async editClient() {
      this.loading = true

      let userData = {
        login: this.clientEdit.login,
        name: this.clientEdit.name,
        status_id: this.clientEdit.status_id,
        // type: this.clientEdit.role.id,
        balance: this.clientEdit.balance,
        // correction_balance: this.clientEdit.correction_balance === null || this.clientEdit.correction_balance ===  '' ? 0 : this.clientEdit.correction_balance,
        phone: this.clientEdit.phone,
        manager_id: this.clientEdit.manager.id,
        // discount: this.clientEdit.discount,
        limit: this.clientEdit.limit,
        surety: this.clientEdit.surety,
        // surety_at: this.clientEdit.surety_at,
        inn: this.clientEdit.inn,
        kpp: this.clientEdit.kpp,
        address: this.clientEdit.address,
        ogrn: this.clientEdit.ogrn,
        account_number: this.clientEdit.account_number,
        bank_name: this.clientEdit.bank_name,
        bik: this.clientEdit.bik,
        correspondent_account: this.clientEdit.correspondent_account,
        okpo: this.clientEdit.okpo,
        ceo_name: this.clientEdit.ceo_name,
        ceo_phone: this.clientEdit.ceo_phone,
        delay: this.clientEdit.delay === null ? '' : this.clientEdit.delay,
        company_name: this.clientEdit.employee,
        contract_number: this.clientEdit.contract_number === null || this.clientEdit.contract_number === 'null' ? '' : this.clientEdit.contract_number,
        integration_id: this.clientEdit.integration_id,
        payment_integration_id: this.clientEdit.payment_integration_id
      }

      if (this.clientEdit.surety_at_model) {
        userData.surety_at = this.clientEdit.surety_at_model
      }

      if (this.clientEdit.contract_date_model) {
        userData.contract_date = this.clientEdit.contract_date_model
      }

      // if (this.clientEdit.surety_file) {
      //   userData.surety_file = this.clientEdit.surety_file
      // }

      if (this.clientEdit.charter_file) {
        userData.charter_file = this.clientEdit.charter_file
      }

      if (this.clientEdit.certificate_of_registration_file) {
        userData.certificate_of_registration_file = this.clientEdit.certificate_of_registration_file
      }

      if (this.clientEdit.extract_from_egryul_file) {
        userData.extract_from_egryul_file = this.clientEdit.extract_from_egryul_file
      }

      if (this.clientEdit.director_order_file) {
        userData.director_order_file = this.clientEdit.director_order_file
      }

      let data = {
        form_data: toFormData(userData),
        id: this.clientEdit.id
      }

      if (this.surety_files_to_remove && this.surety_files_to_remove.length > 0) {
        for (let key in this.surety_files_to_remove) {
          if (this.surety_files_to_remove.hasOwnProperty(key)) {
            console.log(this.surety_files_to_remove[key])
            //filesArr.push(this.files[key])
            data.form_data.append("delete_surety_files[]", this.surety_files_to_remove[key].id);
          }
        }
      }

      if (this.surety_files_to_add && this.surety_files_to_add.length > 0) {
        for (let key in this.surety_files_to_add) {
          if (this.surety_files_to_add.hasOwnProperty(key)) {
            console.log(this.surety_files_to_add[key])
            //filesArr.push(this.files[key])
            data.form_data.append("surety_file[]", this.surety_files_to_add[key].file);
          }
        }
      }

      if (this.supply_files_to_remove && this.supply_files_to_remove.length > 0) {
        for (let key in this.supply_files_to_remove) {
          if (this.supply_files_to_remove.hasOwnProperty(key)) {
            console.log(this.supply_files_to_remove[key])
            //filesArr.push(this.files[key])
            data.form_data.append("delete_supply_contract_files[]", this.supply_files_to_remove[key].id);
          }
        }
      }

      if (this.supply_files_to_add && this.supply_files_to_add.length > 0) {
        for (let key in this.supply_files_to_add) {
          if (this.supply_files_to_add.hasOwnProperty(key)) {
            console.log(this.supply_files_to_add[key])
            //filesArr.push(this.files[key])
            data.form_data.append("supply_contract_files[]", this.supply_files_to_add[key].file);
          }
        }
      }

      await this.editCustomer(data)

      this.loading = false
      //this.closeAll()
    },
  },
  async mounted() {
    await this.getCustomerById({
      id: this.client.id,
      is_client: true
    })

    this.clientEdit = JSON.parse(JSON.stringify(this.client))
    //this.clientEdit.status = this.user_statuses.find(item => item.id === this.clientEdit.user.status_id)
    this.clientEdit.status_id = this.clientEdit.user.status_id;
    this.clientEdit.name = this.clientEdit.user.name
    if (this.clientEdit.manager) {
      this.clientEdit.manager.name = this.clientEdit.manager.user?.name
    }
    this.clientEdit.login = this.clientEdit.user?.login
    this.clientEdit.phone = this.clientEdit.user?.phone
  }
}
</script>

<style scoped lang="scss">
@import "../../styles/variables";

.popup {
  :deep(.loader) {
    height: 200%;
    width: 200%;
    left: -50%;
    top: -50%;
    border-radius: 25px;
    transform: scale(0.5);
    svg {
      position: unset;
    }
  }
}

.popup {
  max-width: unset;
  width: 790px;

  .form {
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    gap: 6px;

    &.fake {
      margin-bottom: 0;
    }

    .left, .right {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 2px;
    }
  }
}
</style>
