<template>
  <div class="popup small">
    <Loader v-if="loading" />
    <h4 class="popup__title middle">Удаление клиента</h4>

    <span class="popup__subtitle">
      Вы точно желаете удалить данного клиента?
    </span>

    <div class="actions">
      <button class="button btn-primary" @click="closeAll()">Отмена</button>
      <button class="button btn-primary" @click="deleteCustomer()">Удалить</button>
    </div>
  </div>
</template>

<script>
import {popups} from '@/mixins/popups'
import {mapActions, mapState} from "vuex";
import Loader from '@/components/common/Loader.vue'

export default {
  name: "removeClientPopup",
  mixins: [popups],
  components: {
    Loader
  },
  data: () => ({
    loading: false,
  }),
  computed: {
    ...mapState({
      client: state => state.popups.client,
    })
  },
  methods: {
    ...mapActions({
      removeCustomer: 'removeCustomer',
    }),
    async deleteCustomer() {
      this.loading = true
      await this.removeCustomer(this.client.ID || this.client.id)

      this.loading = false
      //this.closeAll()
    },
  },
  mounted() {}
}
</script>

<style scoped lang="scss">
@import "../../styles/variables";

.popup {
  :deep(.loader) {
    height: 200%;
    width: 200%;
    left: -50%;
    top: -50%;
    border-radius: 25px;
    transform: scale(0.5);
    svg {
      position: unset;
    }
  }
}
</style>