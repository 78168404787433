<template>
  <div class="page-container">
    <PageHeader icon="dashboard-active.svg" title="Дашборд" />
    <div v-if="userRole === 3" class="page-content client">
      <div class="left">
        <div class="notify-message">
          <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M40 70C56.5685 70 70 56.5685 70 40C70 23.4315 56.5685 10 40 10C23.4315 10 10 23.4315 10 40C10 56.5685 23.4315 70 40 70Z" stroke="#E2350D" stroke-width="4" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M45 53.333C45 56.0944 42.7614 58.333 40 58.333C37.2386 58.333 35 56.0944 35 53.333C35 50.5716 37.2386 48.333 40 48.333C42.7614 48.333 45 50.5716 45 53.333Z" fill="#E2350D"/>
            <path d="M40 40L40 26.6667" stroke="#E2350D" stroke-width="8" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <div class="notify-message__text">
            <p>
              Уважаемые клиенты!<br><br>
              С радостью сообщаем вам, что обновление личного кабинета успешно выпущено! Все данные будут отображаться корректно в течение 2-3 дней.<br>
              Если у вас возникнут вопросы, пожелания или вы заметите неточности, пожалуйста, обращайтесь к своему персональному менеджеру  или по электронной почте: info@smartoilazs.ru.<br><br>
              Спасибо за ваше понимание и поддержку!
            </p>
          </div>
        </div>
        <div
          class="card debt-card"
          v-if="
            client_details && client_details.finance && client_details.finance.debt_last_month > 0
          "
        >
          <div class="texts">
            <div class="name">Задолженность за предыдущий месяц:</div>
            <div class="debt">{{ setFormatPrice(Number(client_details.finance.debt_last_month).toFixed(2)) }} ₽</div>
          </div>
          <!--          <button class="pay-btn">Оплатить</button>-->
        </div>
        <div class="cards">
          <div
            class="card double-width credit"
            v-if="client_details && client_details.finance && client_details.finance.limit"
          >
            <div class="name">
              <span v-html="'Доступный<br/> кредитный лимит'"></span>
            </div>
            <div class="value green">
              <span><span class="orange-text">{{ setFormatPrice(getSum()) }}</span> из {{ setFormatPrice(client_details.finance.limit) }}</span>
              <div class="additional-text" v-if="client_details?.finance?.end_credit_limit_date">
                дата окончания: {{ getFormatDate(client_details.finance.end_credit_limit_date) }}
              </div>
            </div>
          </div>
          <div
            class="card"
            v-if="client_details && client_details.finance && client_details.finance.balance"
          >
            <div class="name">
              <span v-html="'Текущий<br/>баланс<br/>'"></span>
            </div>
            <div
              :class="{
                green: client_details.finance.balance > 0,
                orange: client_details.finance.balance <= 0
              }"
              class="value value-big"
            >
              {{ setFormatPrice(Number(client_details.finance.balance).toFixed(2)) }}
              <br />
              <span class="small-text">
                * Обновление транзакций каждый час.
                <br />
                Выгрузка платежей в 9:30, 14:30, 16:30, 17:30
              </span>
            </div>
          </div>

          <div class="card double-width" v-if="client_details && client_details.finance">
            <div class="wrap">
              <div class="name" v-html="'Среднесуточная<br/>реализация'"></div>
              <div class="button-group">
                <button
                  @click="setActiveButton('roubles')"
                  :class="{ active: activeButton === 'roubles' }"
                  class="switcher left"
                >
                  Рубли
                </button>
                <button
                  @click="setActiveButton('litres')"
                  :class="{ active: activeButton === 'litres' }"
                  class="switcher right"
                >
                  Литры
                </button>
              </div>
            </div>
            <div
              :class="{
                green: getTurnoverValue() > 0,
                orange: getTurnoverValue() <= 0
              }"
              class="value"
            >
              {{
                activeButton === 'roubles'
                  ? setFormatPrice(client_details.finance.daily_turnover_sum.toFixed(2))
                  : setFormatPrice(client_details.finance.daily_turnover_fuel.toFixed(2))
              }}
            </div>
          </div>
          <div class="card" v-if="client_details && client_details.finance">
            <div class="name">
              <span v-html="'Прогнозируемый<br/>баланс через'"></span>
              <v-select
                class="blue"
                :options="daysOptions"
                label="title"
                v-model="daysBalance"
                :clearable="false"
                :searchable="false"
              ></v-select>
            </div>
            <div
              :class="{
                green: client_details.finance[`predict_balance_${daysBalance.value}`] > 0,
                orange: client_details.finance[`predict_balance_${daysBalance.value}`] <= 0
              }"
              class="value"
            >
              {{ setFormatPrice(client_details.finance[`predict_balance_${daysBalance.value}`].toFixed(2)) }}
            </div>
          </div>

          <div
            class="fuel-card card double-width"
            v-if="client_details && client_details.cards_info"
          >
            <div class="name" v-html="'Количество<br/>топливных карт'"></div>
            <div class="value">{{ client_details.cards_info.cards_count }}</div>
          </div>
          <div class="card" v-if="client_details && client_details.finance">
            <div class="name" v-html="'Дата последнего<br/>платежа'"></div>
            <div class="blue value">
              {{ getPaymentDate(client_details.finance.last_payment_datetime) }}
            </div>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="card personal" v-if="client_details && client_details.manager_user">
          <div class="name">Информация о персональном менеджере</div>
          <div class="info">
            <div class="avatar">{{ getAvatarText(client_details.manager_user.name) }}</div>
            <div class="personal-data">
              <div class="names">{{ client_details.manager_user.name }}</div>
              <div class="job">Менеджер</div>
              <div class="phone">{{ client_details.manager_user.phone }}</div>
              <a class="mail" :href="`mailto:${client_details.manager_user.login}`" target="_blank">
                {{ client_details.manager_user.login }}
              </a>
            </div>
          </div>
        </div>
        <div class="card contracts">
          <div class="name">Информация о договорах</div>
          <div class="contracts-info">
            <div class="item" v-if="client_details && client_details.contract">
              <div class="item_key">Номер договора</div>
              <div class="item_value">{{ client_details.contract.name }}</div>
            </div>
            <div class="item" v-if="client_details && client_details.contract">
              <div class="item_key">Дата подписания</div>
              <div class="item_value">{{ getFormatDate(client_details.contract.date) }}</div>
            </div>
            <div class="item" v-if="client_details && client_details.user_status_id">
              <div class="item_key">Статус</div>
              <div
                class="item_value value-active"
                :class="{
                  'value-active': client_details.user_status_id === 1,
                  'value-inactive': client_details.user_status_id === 2
                }"
              >
                {{ client_details.user_status }}
              </div>
            </div>
          </div>
        </div>
        <div class="card limits" v-if="client_details?.finance?.credit_limit_history?.length > 0">
          <div class="name">Список сумм взятых в кредит</div>
          <table class="credit-table">
            <thead>
              <th>
                <div class="inner">
                  <div class="text">Дата</div>
                  <!--                <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8.75 1.66675H1.25L4.16667 5.19175V7.91675L5.83333 8.75008V5.19175L8.75 1.66675Z" stroke="#666687" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>-->
                </div>
              </th>
              <th class="amount">
                <div class="inner">
                  <div class="text">Сумма взятая в кредит</div>
                  <!--                <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8.75 1.66675H1.25L4.16667 5.19175V7.91675L5.83333 8.75008V5.19175L8.75 1.66675Z" stroke="#666687" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>-->
                </div>
              </th>
            </thead>
            <tbody>
              <tr v-for="credit in credits" :key="`credit_${credit.id}`">
                <td>{{ getFormatDate(credit.date_taken) }}</td>
                <td class="amount">{{ setFormatPrice(credit.amount) }} ₽</td>
              </tr>
            </tbody>
          </table>
          <button
            v-if="client_details.finance.credit_limit_history.length > 3"
            @click="showAllCredits = !showAllCredits"
            class="show-more"
          >
            <span class="text" v-if="showAllCredits">Скрыть</span>
            <span class="text" v-else>Посмотреть еще</span>
            <svg
              :class="{ rotate: showAllCredits }"
              xmlns="http://www.w3.org/2000/svg"
              width="17"
              height="16"
              viewBox="0 0 17 16"
              fill="none"
            >
              <path
                d="M11.1663 6.66699L8.49967 9.33366L5.83301 6.66699"
                stroke="#6DB534"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
    <div v-else-if="userRole === 2" class="page-content">
      <!--column-->
      <div class="tab-content table">
        <TableComponent
          :columns="columns"
          :rows="tableRows_1"
          :title="'Клиенты'"
          :isBordered="true"
          :totalCount="totalCount"
          :pageSize="pageSize"
          :pageCount="pageCount"
          :currentPage="Number(currentPage)"
          :isLoading="loading"
          @downloadXSLX="downloadXSLX"
          @regetData="regetData($event)"
          :noData="noData"
          :additionalRows="additionalRows_1"
          :show-additional-table-header="false"
          :actionType="'managerDashboardActions'"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapMutations, mapState } from 'vuex'
  import vSelect from 'vue-select'

  export default {
    name: 'Dashboard',
    components: { vSelect },
    data: () => ({
      cards: [
        {
          name: `Текущий<br/>баланс`,
          value: '0 ₽',
          color: 'green'
        },
        {
          name: `Кредитный<br/>лимит`,
          value: '10 900.00 ₽',
          color: 'green'
        },
        {
          name: `Прогнозируемый<br/>баланс через`,
          value: '-90.00 ₽',
          color: 'orange'
        },
        {
          name: `Среднесуточная<br/>реализация`,
          value: '900.00 ₽',
          color: 'green',
          doubleWidth: true
        },
        {
          name: `Дата последнего<br/>платежа`,
          value: '17 октября',
          color: 'blue'
        }
      ],
      activeButton: 'roubles',
      tableColumns_1: [
        {
          name: 'Наименование клиента',
          field: 'client_name',
          filter_name: 'users_ids[]',
          filter_value: '',
          order: '',
          multiple: true
        },
        {
          name: 'Текущий\n' + 'баланс',
          field: 'current_balance_format',
          fieldDefault: 'current_balance',
          filter_name: 'current_balance',
          filter_value: '',
          order: '',
          balance_field: true,
          filter_name_from: 'current_balance_from',
          filter_value_from: '',
          filter_name_to: 'current_balance_to',
          filter_value_to: '',
          from_to_fields: true
        },
        {
          name: 'Оборот\n' + 'за текущий\n' + 'месяц',
          field: 'turnover_current_month',
          filter_name: 'turnover_current_month',
          filter_value: '',
          order: '',
          filter_name_from: 'turnover_current_month_from',
          filter_value_from: '',
          filter_name_to: 'turnover_current_month_to',
          filter_value_to: '',
          from_to_fields: true
        },
        {
          name: 'Потребление\n' + 'за текущий месяц\n' + '(тип топлива n)',
          field: 'fuel_type_month',
          filter_name: 'fuel_type_month_ids[]',
          filter_value: '',
          order: '',
          fuel_type: true,
          multiple: true
        },
        {
          name: 'Оборот\n' + 'за текущий\n' + 'год',
          field: 'turnover_current_year',
          filter_name: 'turnover_current_year',
          filter_value: '',
          order: '',
          filter_name_from: 'turnover_current_year_from',
          filter_value_from: '',
          filter_name_to: 'turnover_current_year_to',
          filter_value_to: '',
          from_to_fields: true
        },
        {
          name: 'Потребление\n' + 'за текущий год\n' + '(тип топлива n)',
          field: 'fuel_type_year',
          filter_name: 'fuel_type_year_ids[]',
          filter_value: '',
          order: '',
          fuel_type: true,
          multiple: true
        },
        {
          name: 'Среднесуточная\n' + 'реализация, в литрах/рублях',
          field: 'daily_turnover_fuel',
          filter_name: 'daily_turnover_fuel',
          filter_value: '',
          order: '',
          filter_name_from: 'daily_turnover_fuel_from',
          filter_value_from: '',
          filter_name_to: 'daily_turnover_fuel_to',
          filter_value_to: '',
          from_to_fields: true,
          daily_turnover: true,
          no_filter: true
        },
        {
          name: 'Кредитный лимит',
          field: 'limit',
          filter_name: 'limit',
          filter_value: '',
          order: '',
          filter_name_from: 'limit_from',
          filter_value_from: '',
          filter_name_to: 'limit_to',
          filter_value_to: '',
          from_to_fields: true
        },
        {
          name: 'Дата окончания кредитного лимита',
          field: 'end_credit_limit_date',
          date_type: true,
          // filter_name: 'datetime',
          // filter_value: '',
          order: ''
          //orderName: true
        }
      ],
      tableRows_1: [],
      additionalRows_1: [
        {
          client_name: `ИТОГО`,
          current_balance: ``,
          turnover_current_month: ``,
          fuel_type_month: ``,
          turnover_current_year: ``,
          fuel_type_year: ``
        }
      ],
      noData: false,
      loading: false,
      totalCount: 0,
      pageSize: 0,
      pageCount: 0,
      currentPage: 0,

      daysOptions: [
        { title: '7 дней', value: 7 },
        { title: '14 дней', value: 14 },
        { title: '21 день', value: 21 }
      ],
      daysBalance: { title: '14 дней', value: 14 },
      showAllCredits: false
    }),
    computed: {
      ...mapState({
        userRole: (state) => state.user.userRole,
        columns: (state) => state.popups.columns,
        myUser: (state) => state.user.myUser,
        manager_dashboard: (state) => state.admin.manager_dashboard,
        client_details: (state) => state.customer.client_details
      }),
      debt() {
        if (this.client_details.finance.balance >= 0) {
          return 0
        } else {
          return Math.abs(Number(this.client_details.finance.balance).toFixed(2))
        }
      },
      credits() {
        if (this.showAllCredits) {
          return this.client_details.finance.credit_limit_history
          //return Array(25).fill(this.client_details.finance.credit_limit_history).flat()
        } else {
          return this.client_details.finance.credit_limit_history.slice(0, 3)
          //return Array(25).fill(this.client_details.finance.credit_limit_history).flat().slice(0, 3)
        }
      }
    },
    watch: {
      myUser(val) {
        if (val && val.type > 0) {
          if (this.userRole === 2) {
            this.getFuelTypesReq()
            // this.getManagerDashboard()
            let data = {
              size: 100,
              pageNumber: 1
            }

            this.regetData(data)
          }
          if (this.userRole === 3) {
            this.getClientDetails()
          }
        }
      },
      async manager_dashboard(val) {
        await this.setTablesData()
      }
    },
    methods: {
      ...mapActions({
        getClientDetails: 'getClientDetails',
        getManagerDashboard: 'getManagerDashboard',
        getFuelTypesReq: 'getFuelTypes'
      }),
      ...mapMutations({
        setColumns: 'SET_COLUMNS',
        setPaginationFilters: 'SET_PAGINATION_FILTERS',
        resetPaginationFilters: 'RESET_PAGINATION_FILTERS'
      }),
      setFormatPrice(val) {
        let p;
        if (String(val).includes('.')) {
          p = val.split(".");
          return p[0].split("").reverse().reduce(function(acc, val, i, orig) {
            return val + (val !== "-" && i && !(i % 3) ? " " : "") + acc;
          }, "") + '.' + p[1];
        } else {
          p = String(val)
          return p.split("").reverse().reduce(function(acc, val, i, orig) {
            return val + (val !== "-" && i && !(i % 3) ? " " : "") + acc;
          }, "");
        }
      },
      getSum() {
        let a = Number(this.client_details.finance.balance);
        let b = Number(this.client_details.finance.limit);
        return (b + a).toFixed(2)
      },
      setActiveButton(val) {
        this.activeButton = val
      },
      async regetData(data) {
        console.log(data)
        this.loading = true

        let args = [
          {
            name: 'page',
            value: data.pageNumber
          },
          {
            name: 'page_size',
            value: data.size
          }
        ]

        if (Math.ceil(this.totalCount / data.size) < data.pageNumber) {
          args[0].value = 1
        }

        this.setPaginationFilters(args)

        await this.getManagerDashboard()
      },
      async downloadXSLX() {
        this.$store.commit('SET_DOWNLOAD_LOADING', true)
        await this.getManagerDashboard(true)
        this.$store.commit('SET_DOWNLOAD_LOADING', false)
      },
      async setTablesData() {
        this.totalCount = this.manager_dashboard.total_count
        this.pageSize = this.manager_dashboard.page_size
        this.pageCount = this.manager_dashboard.page_count
        this.currentPage = this.manager_dashboard.current_page
        let dashboardData = []

        for (let i = 0; i < this.manager_dashboard.data.length; i++) {
          let data = this.manager_dashboard.data[i]
          data.actionType = 'managerDashboardActions'
          data.current_balance_format = this.setFormatPrice(data.current_balance.toFixed(2))
          data.turnover_current_month = this.setFormatPrice(Number(data.turnover_current_month).toFixed(2))
          data.turnover_current_year = this.setFormatPrice(Number(data.turnover_current_year).toFixed(2))
          data.limit = this.setFormatPrice(Number(data.limit).toFixed(2))

          dashboardData.push(data)
        }

        // this.additionalRows_1[0].current_balance =
        //   this.setFormatPrice(this.manager_dashboard.total.total_balance.toFixed(2))
        this.additionalRows_1[0].turnover_current_month =
          this.setFormatPrice(this.manager_dashboard.total.total_turnover_current_month.toFixed(2))
        this.additionalRows_1[0].fuel_type_month =
          this.setFormatPrice(this.manager_dashboard.total.total_fuel_type_month.toFixed(2))
        this.additionalRows_1[0].turnover_current_year =
          this.setFormatPrice(this.manager_dashboard.total.total_turnover_current_year.toFixed(2))
        this.additionalRows_1[0].fuel_type_year =
          this.setFormatPrice(this.manager_dashboard.total.total_fuel_type_year.toFixed(2))

        this.tableRows_1 = dashboardData

        if (!this.tableRows_1 || this.tableRows_1.length === 0) {
          this.noData = true
        } else {
          this.noData = false
        }

        if (this.columns) {
          this.setColumns(this.columns)
        } else {
          this.setColumns(this.tableColumns_1)
        }

        this.loading = false
      },
      getPaymentDate(date) {
        if (date) {
          let split = date.split('-')
          let day = split[2]
          const monthArr = [
            'Января',
            'Февраля',
            'Марта',
            'Апреля',
            'Мая',
            'Июня',
            'Июля',
            'Августа',
            'Сентября',
            'Октября',
            'Ноября',
            'Декабря'
          ]
          let monthName = monthArr[Number(split[1]) - 1]
          return `${day} ${monthName}`
        } else {
          return '-'
        }
      },
      getTurnoverValue() {
        return this.activeButton === 'roubles'
          ? this.client_details.finance.daily_turnover_sum
          : this.client_details.finance.daily_turnover_fuel
      },
      getAvatarText(name) {
        let text = ''
        if (name) {
          let nameSplit = name.split(' ')
          nameSplit.forEach((item, index) => {
            if (index < 2) {
              text += item[0]
            }
          })
        }
        return text
      },
      getFormatDate(date) {
        if (date) {
          let splitDate = date.split('-')
          return `${splitDate[2]}.${splitDate[1]}.${splitDate[0]}`
        }
        return '';
      }
    },
    async mounted() {
      this.setColumns(null)

      this.resetPaginationFilters()

      setTimeout(() => {
        if (this.userRole === 2) {
          this.getFuelTypesReq()
          // this.getManagerDashboard()
          let data = {
            size: 100,
            pageNumber: 1
          }

          this.regetData(data)
        }
        if (this.userRole === 3) {
          this.getClientDetails()
        }
      }, 200)
    }
  }
</script>

<style scoped lang="scss">
  @import '../../styles/variables';

.orange-text {
  color: $orange;
}
.notify-message {
  display: flex;
  align-items: flex-start;
  width: calc(100% - 84px);
  margin-bottom: 30px;
  padding: 25px;
  border-radius: 12px;
  border: 1px solid #E2350D;

  svg {
    min-width: 80px;
    margin-right: 20px;
  }

  &__text {
    text-align: left;

    p {
      font-size: 14px;
      font-family: SF_Pro_Medium, Arial, sans-serif;
      font-weight: 500;
      line-height: 20px;
    }

    span {
      font-size: 12px;
      font-family: SF_Pro_Regular, Arial, sans-serif;
      font-weight: 400;
      line-height: normal;
    }
  }
}

  .page-content {
    &.column {
      flex-direction: column;
    }

    .left {
      display: flex;
      flex-direction: column;
      padding-top: 30px;
      gap: 30px;
      //padding-right: 30px;
      //max-width: 630px;
      //min-width: 630px;
      //flex-wrap: wrap;
      //width: 75%;
      border-right: 1px solid rgba(26, 26, 24, 0.1);

      @media screen and (max-width: 1440px) {
        //width: 60%;
        padding-top: 15px;
      }
      @media screen and (max-width: 1000px) {
        padding-bottom: 40px;
        border-bottom: 1px solid rgba(26, 26, 24, 0.1);
      }
    }
    .right {
      display: flex;
      flex-direction: column;
      padding-top: 30px;
      padding-left: 30px;
      width: 50%;
      max-width: 500px;
      flex-shrink: 1;
      gap: 30px;

      .card {
        gap: 0;
      }

      @media screen and (max-width: 1440px) {
        //width: 60%;
        padding-top: 15px;
        padding-left: 15px;
        gap: 30px 15px;
      }
      @media screen and (max-width: 1000px) {
        padding-top: 40px;
        padding-bottom: 40px;
      }
    }

    &.client {
      display: flex;
      @media screen and (max-width: 1000px) {
        flex-direction: column;
        overflow: hidden;
        height: auto;
        padding-left: 20px;
        padding-right: 20px;

        .left,
        .right {
          width: 100%;
          box-sizing: border-box;
        }
        .left {
          border-right: none;
        }
        .right {
          padding-left: 0;
          flex-direction: row;
          gap: 30px 16px;
          max-width: unset;
        }
      }
      @media screen and (max-width: 800px) {
        .right {
          flex-wrap: wrap;
        }
      }
    }
  }
  .cards {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    //width: calc(100% - 30px);
    padding-right: 30px;
    box-sizing: border-box;

    @media screen and (max-width: 1440px) {
      padding-right: 15px;
      gap: 30px 15px;
    }

    @media screen and (max-width: 1000px) {
      gap: 30px 16px;
      padding-right: 0;
    }
  }

  .card {
    //width: 128px;
    width: calc(100% / 3 - 15px);
    height: auto;
    min-height: 111px;
    flex-shrink: 0;
    border-radius: 12px;
    background: #ffffff;
    //margin-right: 30px;
    //margin-bottom: 30px;
    padding: 30px 27px 39px 25px;
    position: relative;
    box-sizing: border-box;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 20px;

    .wrap {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }

    &.double-width {
      //width: 338px;
      width: calc((100% / 3) * 2 - 15px);
    }

    &.debt-card {
      height: auto;
      border-radius: 12px;
      background: linear-gradient(
        341deg,
        #ffec00 -27.5%,
        #ffea00 -26.24%,
        #f7b403 5.17%,
        #f08a05 34.06%,
        #ec6b07 60.45%,
        #e95908 81.81%,
        #e85208 98.14%
      );
      box-shadow: 0px 4px 24px -6px rgba(234, 101, 9, 0.64);
      width: calc(100% - 30px);
      //width: 100%;
      box-sizing: border-box;

      flex-direction: row;

      .texts {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        border: none !important;
      }

      .name {
        //width: 160px;
        color: #ffffff;
        font-family: SF_Pro_Regular, Arial, sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
      }
      .debt {
        color: #ffffff;
        text-align: center;
        font-family: SF_Pro_SemiBold, Arial, sans-serif;
        font-size: 30px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }

      .pay-btn {
        display: inline-flex;
        padding: 12px 16px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        background: transparent;

        border-radius: 4px;
        border: 1px solid #ffffff;

        color: #ffffff;
        font-feature-settings: 'clig' off, 'liga' off;

        /* Omega (Bold) - Button text */
        font-family: SF_Pro_Bold, Arial, sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 16px; /* 114.286% */
        text-transform: uppercase;

        transition: all 0.3s;

        &:hover {
          background: #ffffff;
          color: #f08a05;
        }
      }

      @media screen and (max-width: 1440px) {
        width: calc(100% - 15px);
      }
      @media screen and (max-width: 1000px) {
        width: 100%;
      }
    }

    .name {
      color: $black;
      font-family: SF_Pro_Medium, Arial, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-align: left;
    }

    .value {
      width: 100%;
      text-align: right;
      font-family: SF_Pro_SemiBold, Arial, sans-serif;
      font-size: 19px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      position: relative;
      display: flex;
      flex-direction: column;
      gap: 10px;

      &:before {
        content: '';
        position: absolute;
        left: 0;
        bottom: -2px;
        width: 4px;
        //height: 30px;
        height: 100%;
        min-height: 30px;
        flex-shrink: 0;
        border-radius: 20px;
      }

      &.value-big {
        &:before {
          //height: 50px;
        }
      }

      &.green {
        color: $green;
        &:before {
          background: $green;
        }
      }
      &.orange {
        color: $orange;
        &:before {
          background: $orange;
        }
      }
      &.blue {
        color: $blue;
        &:before {
          background: $blue;
        }
      }

      .additional-text {
        //font-family: SF_Pro_Display;
        font-size: 12px;
        font-weight: 500;
        line-height: 14.32px;
        text-align: right;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #868685;
      }
    }

    &.personal,
    &.contracts,
    &.limits {
      //width: calc(100% - 60px);
      //width: calc(100% - 50px);
      width: 100%;
      max-width: 375px;
      height: unset;
      flex-shrink: 0;
      padding: 30px;
      padding-top: 20px;
      @media screen and (min-width: 1440px) {
        max-width: unset;
        //width: 100%;
      }

      .name {
        margin-bottom: 31px;
        text-align: left;
      }

      .info {
        display: flex;
        .avatar {
          height: 48px;
          width: 48px;
          flex-shrink: 0;
          background: $green;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #ffffff;
          text-align: center;
          font-family: SF_Pro_Medium, Arial, sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          margin-right: 16px;
        }
        .personal-data {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          .names {
            color: $black;
            font-family: SF_Pro_SemiBold, Arial, sans-serif;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            margin-bottom: 9px;
            text-align: left;
          }
          .job {
            color: $grey_1;
            font-family: SF_Pro_Regular, Arial, sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-bottom: 16px;
          }
          .phone {
            margin: 0 0 5px;
            font-size: 16px;
            font-weight: 600;
          }
          .mail {
            color: var(--Blue-1, #2f80ed);
            font-family: SF_Pro_Regular, Arial, sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }
      }

      .contracts-info {
        display: inline-flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        width: 100%;

        .item {
          display: flex;
          width: 100%;
          justify-content: space-between;
          align-items: flex-start;
          text-align: left;

          &_key {
            color: $grey_1;
            font-family: SF_Pro_Regular, Arial, sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
          &_value {
            color: $black;
            text-align: right;
            font-family: SF_Pro_Medium, Arial, sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;

            &.value-active {
              position: relative;
              color: $green;

              &:before {
                content: '';
                position: absolute;
                left: -12px;
                bottom: 6.5px;
                width: 6px;
                height: 6px;
                flex-shrink: 0;
                border-radius: 50%;
                background: $green;
              }
            }

            &.value-inactive {
              position: relative;
              color: $orange;

              &:before {
                content: '';
                position: absolute;
                left: -12px;
                bottom: 6.5px;
                width: 6px;
                height: 6px;
                flex-shrink: 0;
                border-radius: 50%;
                background: $orange;
              }
            }
          }
        }
      }

      @media screen and (max-width: 1000px) {
        width: unset;
        max-width: unset;
        flex-shrink: 1;
        flex-grow: 1;
        flex-basis: 50%;
      }
    }

    &.limits {
      max-height: 365px;
      overflow: auto;
      padding-top: 0 !important;

      .name {
        margin-top: 20px;
      }
    }
  }

  .button-group {
    //position: absolute;
    top: 30px;
    right: 27px;
    display: inline-flex;
    align-items: center;
    box-shadow: 0px 2px 5px 0px rgba(38, 51, 77, 0.03);

    .switcher {
      display: flex;
      height: 40px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border: 2px solid #f5f6f7;
      background: #ffffff;
      color: $grey_2;
      text-align: center;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: SF_Pro_Medium, Arial, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 6px; //20px; /* 166.667% */

      &.left {
        padding: 15px 15px 15px 20px;
        border-radius: 30px 0px 0px 30px;
      }
      &.right {
        padding: 15px 20px 15px 15px;
        border-radius: 0px 30px 30px 0px;
      }

      &.active {
        color: $green;
      }

      transition: all 0.3s;

      &:hover {
        background: $green;
        color: #ffffff;
      }
    }
  }

  .fuel-card {
    //width: 338px;
    width: calc(66% - 80px);
    //height: 38px;
    flex-shrink: 0;
    border-radius: 12px;
    background: #ffffff;
    padding: 34px 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    height: inherit;
    flex-direction: row;

    .name {
      text-align: left;
      color: $black;
      font-family: SF_Pro_Medium, Arial, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      width: inherit;
    }

    .value {
      color: #ffffff;
      text-align: center;
      font-family: SF_Pro_SemiBold, Arial, sans-serif;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      z-index: 1;
      padding-right: 45px;
      text-align: right;
    }

    &:before {
      content: '';
      position: absolute;
      right: 17px;
      bottom: calc(50% - 37.5px);
      width: 120px;
      height: 75px;
      flex-shrink: 0;
      border-radius: 20px;

      background: url('../../assets/fuel-card.svg');
      background-repeat: no-repeat;
      background-size: contain;
    }
  }

  @media screen and (max-width: 1440px) {
    .card {
      padding: 20px;
      //width: calc(33% - 68px);
      //width: calc(33% - 30px);
      //width: calc((100% / 3) - 30px);
      width: calc((100% / 3) - 7.5px);
      &.double-width {
        //width: 338px;
        //width: calc(66% - 68px);
        //width: calc(66% - 30px);
        //width: calc((100% / 3)*2 - 30px);
        width: calc((100% / 3) * 2 - 7.5px);
      }
      .name {
        //font-size: 12px;
      }
      .value {
        //font-size: 15px;
      }

      &.personal,
      &.contracts,
      &.limits {
        height: unset;
        padding: 20px;
        .name {
          margin-bottom: 24px;
        }
        .info {
          .personal-data {
            .names {
              font-size: 14px;
            }
            .job {
              font-size: 12px;
            }
          }
        }

        .contracts-info {
          gap: 14px;

          .item {
            &_key,
            &_value {
              font-size: 13px;
            }
          }
        }
      }
    }
    .fuel-card {
      box-sizing: border-box;
      height: auto;
      width: calc((100% / 3) * 2 - 30px);
      .name {
        //font-size: 12px;
      }
      .value {
        font-size: 20px;
      }
    }
  }

  @media screen and (max-width: 1000px) {
    .card {
      padding: 30px 23px 37px 23px;
      //flex-basis: calc((100% / 3) - 20px);
      width: calc(100% / 3 - (16px * 2) / 3);
      &.double-width {
        //flex-basis: calc((100% / 3)*2 - 10px);
        width: calc(100% / 2 - 8px);
        flex-grow: 1;
      }
    }
    .fuel-card {
      width: calc((100% / 3) * 2 - 10px);
    }
  }
  @media screen and (max-width: 640px) {
    .card {
      width: calc(100% / 2 - 8px);

      &.double-width {
        width: 100%;
        order: 5;
      }

      &:first-of-type {
        order: 2;
      }
      &:nth-of-type(2) {
        order: 1;
      }
      &:nth-of-type(4) {
        order: 3;
      }
      &:nth-of-type(6) {
        order: 4;
      }

      &.credit {
        width: calc((100% / 2) - 8px);
      }
    }
    .fuel-card {
      width: 100%;
      order: 6;
    }
  }

  .tab-content {
    margin-top: 30px;
  }

  .small-text {
    display: flex;
    justify-content: flex-end;
    margin-left: 10px;
    color: #1a1a18;
    font-family: SF_Pro_Medium, Arial, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 1;
    text-align: left;
  }

  .credit-table {
    width: 100%;

    thead {
      position: sticky;
      top: -1px;
      background: #fff;
    }
    td,
    th {
      padding: 10px;
      border-bottom: 1px solid #f1f8eb;
      &:not(.amount) {
        text-align: left;
      }

      &.amount {
        .inner {
          justify-content: center;
        }
      }

      .inner {
        display: flex;
        //padding: 10px;
        align-items: center;
        flex: 1 0 0;
        align-self: stretch;
        gap: 5px;
      }
    }

    th {
      color: #575656;
      font-feature-settings: 'liga' off, 'clig' off;
      //font-family: "SF Pro Text";
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px; /* 133.333% */

      svg {
        width: 10px;
        height: 10px;
        flex-shrink: 0;
      }
    }
  }

  .show-more {
    display: flex;
    align-items: center;
    background: transparent;
    outline: none;
    border: none;
    margin: auto;
    margin-top: 15px;

    color: #6db534;
    font-feature-settings: 'liga' off, 'clig' off;
    //font-family: "SF Pro Text";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 133.333% */

    svg {
      &.rotate {
        transform: rotate(180deg);
      }
    }
  }
</style>
