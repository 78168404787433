<template>
  <div class="popup">
    <Loader v-if="loading" />
    <h4 class="popup__title middle">Дата блокировки изменения скидок/наценок</h4>
    <div v-if="date" class="form">
      <div class="form__item">
        <div class="label">Дата</div>
        <div class="input">
          <VueDatePicker
            v-model="date"
            :enable-time-picker="false"
            :format="'dd.MM.yyyy'"
            locale="ru"
            cancelText="Отмена"
            selectText="Выбрать"
            class="only-day-picker"
            :clearable="false"
          />
        </div>
      </div>
    </div>

    <div class="actions">
      <button class="button btn-primary" @click="closeAll()">Отмена</button>
      <button :disabled="!date" class="button btn-primary" @click="saveDate()">Сохранить</button>
    </div>
  </div>
</template>

<script>
  import { popups } from '@/mixins/popups'
  import { mapActions, mapState } from 'vuex'
  import VueDatePicker from '@vuepic/vue-datepicker'
  import Loader from '@/components/common/Loader.vue'

  export default {
    name: 'setDiscountBlockDay',
    components: { 
      VueDatePicker,
      Loader
    },
    mixins: [popups],
    data: () => ({
      loading: false,
      date: null
    }),
    computed: {
      ...mapState({
        discountBlockDay: (state) => state.customer.discountBlockDay,
        showDiscountBlockDayPopup: (state) => state.popups.showDiscountBlockDayPopup
      })
    },
    methods: {
      ...mapActions({
        getDiscountBlockDay: 'getDiscountBlockDay',
        setDiscountBlockDay: 'setDiscountBlockDay'
      }),
      async saveDate() {
        this.loading = true
        let date = `${this.date.getFullYear()}-${String(this.date.getMonth() + 1).padStart(2, '0')}-${String(this.date.getDate()).padStart(2, '0')}`;
        await this.setDiscountBlockDay({
          value: date
        })
        this.loading = false
      }
    },
    async mounted() {
      this.loading = true
      setTimeout(() => {
        this.date = new Date(this.discountBlockDay)
      }, 100)
      this.loading = false
    }
  }
</script>

<style scoped lang="scss">
@import "../../styles/variables";

.popup {
  :deep(.loader) {
    height: 200%;
    width: 200%;
    left: -50%;
    top: -50%;
    border-radius: 25px;
    transform: scale(0.5);
    svg {
      position: unset;
    }
  }
}

.only-day-picker {
  .dp__calendar_header {
    display: none;
  }
}
</style>
