<template>
  <div class="popup small">
    <Loader v-if="loading" />
    <h4 class="popup__title middle">Сброс пароля пользователя</h4>

    <span class="popup__subtitle">
      Вы точно желаете сбросить пароль данного пользователя?
    </span>

    <div class="actions">
      <button class="button btn-primary" @click="closeAll()">Отмена</button>
      <button class="button btn-primary" @click="resetPassword()">Сбросить</button>
    </div>
  </div>
</template>

<script>
import {popups} from '@/mixins/popups'
import {mapActions, mapState} from "vuex";
import Loader from '@/components/common/Loader.vue'

export default {
  name: "resetUserPasswordPopup",
  mixins: [popups],
  components: {
    Loader
  },
  data: () => ({
    loading: false
  }),
  computed: {
    ...mapState({
      user: state => state.popups.user,
    })
  },
  methods: {
    ...mapActions({
      getAllUsers: 'getAllUsers',
      resetUserPassword: 'resetUserPassword',
    }),
    async resetPassword() {
      this.loading = true

      await this.resetUserPassword({
        email: this.user.email
      })

      this.loading = false
      //this.closeAll()
    },
  },
  mounted() {}
}
</script>

<style scoped lang="scss">
@import "../../styles/variables";

.popup {
  :deep(.loader) {
    height: 200%;
    width: 200%;
    left: -50%;
    top: -50%;
    border-radius: 25px;
    transform: scale(0.5);
    svg {
      position: unset;
    }
  }
}
</style>