<template>
  <div class="popup small">
    <h4 class="popup__title middle">Список интеграций</h4>

    <span v-for="integration of integrations">{{ integration.id }} - {{ integration.name }}</span>

    <div class="actions">
      <button class="button btn-primary" @click="closeAll()">Закрыть</button>
    </div>
  </div>
</template>

<script>

import {popups} from '@/mixins/popups'
import {mapState} from "vuex";
export default {
  name: "integrationList",
  mixins: [popups],
  data: () => ({}),
  computed: {
    ...mapState({
      integrations: state => state.admin.integrations,
    })
  },
  mounted() {}
}
</script>

<style scoped lang="scss">
@import "../../styles/variables";

.popup {
  :deep(.loader) {
    height: 200%;
    width: 200%;
    left: -50%;
    top: -50%;
    border-radius: 25px;
    transform: scale(0.5);
    svg {
      position: unset;
    }
  }
}

span {
  margin-bottom: 5px;
  text-align: left;
}

.actions {
  margin-top: 15px;
}
</style>
