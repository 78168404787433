<template>
  <div class="page-container">
<!--    <div class="page-header">-->
<!--      <div class="title" >-->
<!--        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--          <path fill-rule="evenodd" clip-rule="evenodd" d="M12 4C9.79 4 8 5.79 8 8C8 10.21 9.79 12 12 12C14.21 12 16 10.21 16 8C16 5.79 14.21 4 12 4ZM14 8C14 6.9 13.1 6 12 6C10.9 6 10 6.9 10 8C10 9.1 10.9 10 12 10C13.1 10 14 9.1 14 8ZM18 17C17.8 16.29 14.7 15 12 15C9.3 15 6.2 16.29 6 17.01V18H18V17ZM4 17C4 14.34 9.33 13 12 13C14.67 13 20 14.34 20 17V20H4V17Z" fill="#6DB534"/>-->
<!--        </svg>-->
<!--        <span class="text">-->
<!--            Управление аккаунтом-->
<!--        </span>-->
<!--      </div>-->
<!--    </div>-->
    <PageHeader icon="card-active.svg" :title="'Топливные карты'" />
<!--    <tabs :tabsNames="tabsNames" @firstTabActive="firstTabActive = $event"/>-->
    <div class="page-content">
      <div class="tab-content table">
        <TableComponent
            :noData="noData"
            :columns="columns"
            :rows="tableRows_1"
            :totalCount="totalCount"
            :pageSize="pageSize"
            :pageCount="pageCount"
            :currentPage="Number(currentPage)"
            :isLoading="loading"
            :checkingOn="false"
            :title="'Топливные карты'"
            :isBordered="true"
            :show-additional-table-header="false"
            :isLightFontAdditional="true"
            @downloadXSLX="downloadXSLX"
            @regetData="regetData($event)"
            :actionType="'cardClientActions'"
        />

  <!--      <div class="separator"></div>-->

  <!--      <TableComponent-->
  <!--          :columns="columns_2"-->
  <!--          :rows="tableRows_2"-->
  <!--          :additionalColumns="additionalColumns_2"-->
  <!--          :additionalRows="additionalRows_2"-->
  <!--          :title='`Номер карты: <span style="color: #6DB534">7013420001865393<span/>`'-->
  <!--          :isBordered="true"-->
  <!--          :isLightFont="true"-->
  <!--          :isLightFontAdditional="true"-->
  <!--          :isSecondTable="true"-->
  <!--      />-->
      </div>
    </div>
  </div>
</template>

<script>
import Tabs from "@/components/tabs.vue";
import {mapActions, mapMutations, mapState} from "vuex";

export default {
  name: "AccountManagementFuelCards",
  components: {
    Tabs
  },
  data() {
    return {
      firstTabActive: false,
      tabsNames: [
        { name: 'Реквизиты', linkName: 'accountManagement.requisites' },
        { name: 'Топливные карты', linkName: 'accountManagement.fuelCards' }
      ],
      // tableColumns_1: [
      //   {
      //     name: '№',
      //     field: 'number'
      //   },
      //   {
      //     name: 'Компания',
      //     field: 'company'
      //   },
      //   {
      //     name: 'Номер карты',
      //     field: 'card_number'
      //   },
      //   {
      //     name: 'Статус',
      //     field: 'status'
      //   },
      //   {
      //     name: 'Держатель',
      //     field: 'holder'
      //   },
      //   {
      //     name: 'Водитель',
      //     field: 'driver'
      //   },
      //   {
      //     name: 'PIN код',
      //     field: 'pin'
      //   },
      //   {
      //     name: 'Лимит, в сутки/неделю/месяц',
      //     field: 'limit'
      //   }
      // ],
      tableColumns_1: [
        // {
        //   name: '№',
        //   field: 'id',
        //   filter_name: 'card.id',
        //   filter_value: '',
        //   order: ''
        // },
        // {
        //   name: 'Компания',
        //   field: 'customer_employee',
        //   filter_name: 'customer.employee',
        //   filter_value: '',
        //   order: ''
        // },
        {
          name: 'Номер карты',
          field: 'number',
          filter_name: 'number',
          filter_value: '',
          order: '',
          multiple_search: true,
          search_items: []
        },
        {
          name: 'Держатель',
          field: 'holder',
          filter_name: 'holder',
          filter_value: '',
          order: ''
        },
        {
          name: 'Статус',
          field: 'status_name',
          filter_name: 'status.id',
          filter_value: '',
          order: ''
        },
        // {
        //   name: 'Водитель',
        //   field: 'driver'
        // },
        {
          name: 'PIN код',
          field: 'pin',
          filter_name: 'pin',
          filter_value: '',
          order: ''
        },
        {
          name: 'Лимит, в сутки/месяц',
          field: 'activeLimits',
          filter_name: 'amount_limit',
          filter_name_from: 'amount_limit_from',
          filter_value_from: '',
          filter_name_to: 'amount_limit_to',
          filter_value_to: '',
          from_to_fields: true,
          order: '',
          card_limits: true
        },
        {
          name: 'Израсходовано\n' +
              'лимита',
          field: 'activeLimits',
          filter_name: 'used_value',
          filter_name_from: 'used_value_from',
          filter_value_from: '',
          filter_name_to: 'used_value_to',
          filter_value_to: '',
          from_to_fields: true,
          order: '',
          used_limits: true
        },
        {
          name: '',
          field: 'actions'
        },
      ],
      tableRows_1: [],
      noData: false,
      loading: false,
      totalCount: 0,
      pageSize: 0,
      pageCount: 0,
      currentPage: 0,


      tableColumns_2: [
        {
          name: 'АЗС',
          field: 'station'
        },
        {
          name: '№ АЗС',
          field: 'station_number'
        },
        {
          name: 'Дата',
          field: 'date'
        },
        {
          name: 'Время',
          field: 'time'
        },
        {
          name: 'Товар',
          field: 'good'
        },
        {
          name: 'Кол-во',
          field: 'quantity'
        },
        {
          name: 'Цена',
          field: 'price'
        },
        {
          name: 'Сумма',
          field: 'sum'
        }
      ],
      tableRows_2: [
        {
          station: 'Танеко',
          station_number: '226',
          date: '12.08.2022',
          time: '12:28:03',
          good: 'ДТ',
          quantity: '650.00',
          price: '51.50',
          sum: '33 475',
        },
        {
          station: 'Танеко',
          station_number: '226',
          date: '12.08.2022',
          time: '12:28:03',
          good: 'ДТ',
          quantity: '650.00',
          price: '51.50',
          sum: '33 475',
        }
      ],
      additionalColumns_2: [
        {
          name: 'Тип топлива',
          field: 'fuel_type'
        },
        {
          name: 'ИТОГО литров.',
          field: 'litres_total'
        },
        {
          name: 'ИТОГО',
          field: 'total'
        },
      ],
      additionalRows_2: [
        {
          fuel_type: 'ДТ',
          litres_total: '300',
          total: '1 214 734.5'
        },
        {
          fuel_type: 'ДТ Танеко',
          litres_total: '600',
          total: '142 220'
        },
        {
          fuel_type: '',
          litres_total: '700',
          total: '1 356 954.5'
        },
      ],
    }
  },
  computed: {
    ...mapState({
      cards: state => state.admin.cards,
      cardStatuses: state => state.admin.cardStatuses,
      userRole: state => state.user.userRole,
      columns: state => state.popups.columns,
      actionPopup: state => state.popups.actionPopup,
      paginationFilters: state => state.popups.paginationFilters,
      cardLimits: state => state.user.card_limits,
    }),
  },
  watch: {
    async cards(val) {
      await this.setTablesData()
    },
  },
  methods: {
    ...mapActions({
      getCards: 'getCards',
      getCardStatuses: 'getCardStatuses',
      downloadXLSXFile: 'downloadXLSXFile',
      getCardLimits: 'getCardLimits',
      getFuelTypesReq: 'getFuelTypes',
    }),
    ...mapMutations({
      setColumns: 'SET_COLUMNS',
      setColumns_2: 'SET_COLUMNS_2',
      setPaginationFilters: 'SET_PAGINATION_FILTERS',
      resetPaginationFilters: 'RESET_PAGINATION_FILTERS',
    }),
    async regetData(data) {
      console.log(data)
      this.loading = true

      let args = [
        {
          name: 'page',
          value: data.pageNumber
        },
        {
          name: 'perPage',
          value: data.size
        }
      ]

      if (Math.ceil(this.totalCount/data.size) < data.pageNumber) {
        args[0].value = 1
      }

      this.setPaginationFilters(args)

      await this.getCards()
    },
    async downloadXSLX() {
      this.$store.commit('SET_DOWNLOAD_LOADING', true)
      await this.getCards(true)
      this.$store.commit('SET_DOWNLOAD_LOADING', false)
    },
    async setTablesData() {
      this.totalCount = this.cards.total_count
      this.pageSize = this.cards.page_size
      this.pageCount = this.cards.page_count
      this.currentPage = this.cards.current_page
      let cardsData = []

      for (let i = 0; i < this.cards.data.length; i++) {
        let card = this.cards.data[i]
        card.order_number = i + 1
        card.brand = /*card.cardProvider?.name || */card.brand
        card.status_name = card.status?.name
        let limit_name = '';
        if (Number(card.amount_limit) > 0) {
          limit_name = `${card.amount_limit}, р\\${card.limit?.name}`
        }
        if (Number(card.volume_limit) > 0) {
          limit_name = `${card.volume_limit}, л\\${card.limit?.name}`
        }
        card.limit_name = limit_name
        card.customer_employee = card.customer?.employee
        card.customer_name = card.customer?.user?.name
        // let date = card.expired_at ? new Date(card.expired_at).toLocaleDateString() : null
        // card.expired_at_date = date
        if (card.expired_at) {
          card.expired_at = card.expired_at.split(' ')[0]
        }
        card.checked = false
        card.show_all = false
        //card.driver = '???????'

        card.actionType = 'cardClientActions'
        card.hidden_filed = 'pin'
        card.hidden = true

        if (card.status_name === 'Активен') {
          card.green = {status_name: true}
        } else {
          card.red = {status_name: true}
        }

        cardsData.push(card)
      }

      this.tableRows_1 = cardsData

      if (!this.tableRows_1 || this.tableRows_1.length === 0) {
        this.noData = true
      } else {
        this.noData = false
      }

      if (this.columns) {
        this.setColumns(this.columns)
      } else {
        this.setColumns(this.tableColumns_1)
      }

      this.loading = false
    }
  },
  async mounted() {
    this.setColumns(null)
    this.setColumns_2(null)
    this.resetPaginationFilters()
    // setTimeout(() => {
    //   this.setColumns(this.tableColumns_1)
    // }, 1800)

    await this.getFuelTypesReq()
    await this.getCardLimits()
    await this.getCards()
    await this.getCardStatuses()

    //await this.setTablesData()
  }
}
</script>

<style scoped lang="scss">
@import "../../styles/variables";
.separator {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  height: 1px;
  background: $back;
  width: 100%;
}
.tab-content {
  margin-top: 30px;
}
</style>
