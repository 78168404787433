<template>
  <div class="popup">
    <Loader v-if="loading" />
    <h4 class="popup__title middle">Пакетная <span v-html="functionName"></span> топливных карты</h4>

    <div class="info">
      <div class="left">
        Карты:
      </div>
      <div class="right">
        <span v-for="(number, i) of cardsCheckedNumbers">{{number}}<span v-if="i < cardsCheckedNumbers.length - 1">,&nbsp;</span></span>
      </div>
    </div>

    <div class="actions">
      <button class="button btn-primary" @click="closeAll()">Отмена</button>
      <button class="button btn-primary" @click="changeCardsStatus()">{{buttonName}}</button>
    </div>
  </div>
</template>

<script>
import Loader from '@/components/common/Loader.vue'
import {popups} from '@/mixins/popups'
import {mapActions, mapMutations, mapState} from "vuex";
export default {
  name: "bunchChangeCardStatusPopup",
  mixins: [popups],
  components: {
    Loader
  },
  data: () => ({
    loading: false
  }),
  computed: {
    ...mapState({
      cardsChecked: state => state.popups.cardsChecked,
    }),
    cardsCheckedNumbers() {
      let cardNumbers = []
      for (let i = 0; i < this.cardsChecked.length; i++) {
        let card = this.cardsChecked[i]
        if (card.number) {
          cardNumbers.push(card.number)
        }
      }
      return cardNumbers
    },
    functionName() {
      return this.cardsChecked.every(item => item.status_id === 1 || item.status_id === 3) ? 'разблокировка' : 'блокировка'
    },
    buttonName() {
      return this.cardsChecked.every(item => item.status_id === 1 || item.status_id === 3) ? 'Разблокировать' : 'Блокировать'
    }
  },
  methods: {
    ...mapActions({
      bunchChangeCardsStatus: 'bunchChangeCardsStatus'
    }),
    ...mapMutations({
      setActionPopup: 'SET_ACTION_POPUP',
    }),
    async changeCardsStatus() {
      let cardIds = []
      for (let i = 0; i < this.cardsChecked.length; i++) {
        let card = this.cardsChecked[i]
        if (card.id) {
          cardIds.push(card.id)
        }
      }

      this.loading = true

      let data = {
        action: this.cardsChecked.every(item => item.status_id === 1 || item.status_id === 3) ? 2 : 3,
        cards_ids: cardIds
      }
      await this.bunchChangeCardsStatus(data)
      this.loading = false
      //this.closeAll()
    }
  },
  mounted() {}
}
</script>

<style scoped lang="scss">
@import "../../styles/variables";

.popup {
  :deep(.loader) {
    height: 200%;
    width: 200%;
    left: -50%;
    top: -50%;
    border-radius: 25px;
    transform: scale(0.5);
    svg {
      position: unset;
    }
  }
}

.info {
  width: 100%;
  display: flex;
  border-bottom: 1px solid $back;
  margin-bottom: 27px;

  .left {
    display: flex;
    width: 100px;
    padding: 10px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;

    color: $grey-1;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: SF_Pro_Medium, Arial, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 133.333% */
  }

  .right {
    max-height: 400px;
    overflow: auto;
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
    align-items: center;
    flex: 1 0 0;
    align-self: stretch;

    color: $black;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: SF_Pro_Medium, Arial, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
  }
}
</style>
