<template>
  <div class="popup small">
    <Loader v-if="loading" />
    <h4 class="popup__title middle">Удаление</h4>

    <span class="popup__subtitle">
      Вы точно желаете удалить данную запись?
    </span>

    <div class="actions">
      <button class="button btn-primary" @click="removeFunc('cancel')">Отмена</button>
      <button class="button btn-primary" @click="removeFunc('save')">Удалить</button>
    </div>
  </div>
</template>

<script>
import {popups} from '@/mixins/popups'
import {mapActions, mapMutations, mapState} from "vuex";
import Loader from '@/components/common/Loader.vue'

export default {
  name: "removeReferencesPopup",
  mixins: [popups],
  components: {
    Loader
  },
  data: () => ({
    loading: false,
  }),
  computed: {
    ...mapState({
      isSaveRemoveReferencePopup: state => state.popups.isSaveRemoveReferencePopup,
    })
  },
  methods: {
    ...mapActions({
      removeCustomer: 'removeCustomer',
    }),
    ...mapMutations({
      setIsSaveRemoveReferencePopup: 'SET_IS_SAVE_REMOVE_REFERENCE_POPUP',
    }),
    async removeFunc(type) {
      this.loading = true;
      let flag = 0;
      if (type === 'cancel') {
        flag = 1;
      }
      if (type === 'save') {
        flag = 2;
      }
      this.setIsSaveRemoveReferencePopup(flag)
      this.$forceUpdate();
      this.loading = false;
      this.closeAll()
    },
  },
  mounted() {}
}
</script>

<style scoped lang="scss">
@import "../../styles/variables";

.popup {
  :deep(.loader) {
    height: 200%;
    width: 200%;
    left: -50%;
    top: -50%;
    border-radius: 25px;
    transform: scale(0.5);
    svg {
      position: unset;
    }
  }
}
</style>
