<template>
  <div class="popup">
    <Loader v-if="loading" />
    <h4 class="popup__title middle">Поручительство</h4>

    <div class="form">
      <div class="form__item">
        <div class="label">
          Поручитель
        </div>
        <div class="input">
          <input type="text" disabled v-model="dataSuretyInfoPopup.surety_name">
        </div>
      </div>
      <div class="form__item">
        <div class="label">
          Дата поручительства
        </div>
        <div class="input">
          <input type="text" disabled v-model="dataSuretyInfoPopup.surety_date">
        </div>
      </div>
      <div class="form__item form__item-surety-file">
        <div class="label">
          Файлы
        </div>
        <div class="form__item-surety-file-list">
          <div class="input" v-for="file in dataSuretyInfoPopup.surety_files">
            <input type="text" disabled v-model="file.filename">
            <button @click="getFile(file)">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17.5 12.5V15.8333C17.5 16.2754 17.3244 16.6993 17.0118 17.0118C16.6993 17.3244 16.2754 17.5 15.8333 17.5H4.16667C3.72464 17.5 3.30072 17.3244 2.98816 17.0118C2.67559 16.6993 2.5 16.2754 2.5 15.8333V12.5" stroke="#6DB534" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M5.8335 8.33325L10.0002 12.4999L14.1668 8.33325" stroke="#6DB534" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M10 12.5V2.5" stroke="#6DB534" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="actions">
      <button class="button btn-primary" @click="closeAll()">Закрыть</button>
    </div>
  </div>
</template>

<script>
import {popups} from '@/mixins/popups'
import {mapActions, mapState} from "vuex";
import Loader from '@/components/common/Loader.vue'

export default {
  name: "suretyInfo",
  components: { Loader },
  mixins: [popups],
  data: () => ({
    loading: false
  }),
  computed: {
    ...mapState({
      dataSuretyInfoPopup: state => state.popups.dataSuretyInfoPopup,
    })
  },
  methods: {
    ...mapActions({
      getFileByType: 'getFileByType',
    }),
    async getFile(file) {
      this.loading = true;
      let data = {
        file_type: file.file_type,
        id: file.id,
        fileName: file.filename
      };
      await this.getFileByType(data).then(() => {
        this.loading = false;
      })
    }
  },
  mounted() {}
}
</script>

<style scoped lang="scss">
@import "../../styles/variables";

.popup {
  :deep(.loader) {
    height: 200%;
    width: 200%;
    left: -50%;
    top: -50%;
    border-radius: 25px;
    transform: scale(0.5);
    svg {
      position: unset;
    }
  }
}

.form__item {
  .input {
    position: relative;

    button {
      position: absolute;
      top: 50%;
      right: 10px;
      margin: 0;
      padding: 0;
      background: none;
      border: none;
      outline: none;
      transform: translateY(-50%);
    }
  }
  input {
    padding-right: 30px !important;
    text-overflow: ellipsis !important;

    &:disabled {
      background: none !important;
    }
  }
}

.form__item-surety-file {
  .label {
    margin: 14px auto auto auto !important;
  }

  &-list {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 2px;
  }
}
</style>
