<template>
  <div class="popup small">
    <h4 v-if="isSuccess" class="popup__title middle">Операция выполнена успешно</h4>
    <h4 v-else class="popup__title middle">Ошибка</h4>

    <span v-if="errorText" class="popup__subtitle" v-html="errorText">
    </span>

    <div class="actions">
      <button class="button btn-primary" @click="closeAll()">Закрыть</button>
    </div>
  </div>
</template>

<script>

import {popups} from '@/mixins/popups'
import {mapState} from "vuex";
export default {
  name: "removeClientPopup",
  mixins: [popups],
  data: () => ({}),
  computed: {
    ...mapState({
      errorText: state => state.popups.errorText,
      isSuccess: state => state.popups.isSuccess,
    })
  },
  mounted() {}
}
</script>

<style scoped lang="scss">
@import "../../styles/variables";

.popup {
  :deep(.loader) {
    height: 200%;
    width: 200%;
    left: -50%;
    top: -50%;
    border-radius: 25px;
    transform: scale(0.5);
    svg {
      position: unset;
    }
  }
}

.popup__subtitle {
  max-height: 70vh;
  overflow-y: auto;
}
</style>
