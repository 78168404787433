<template>
  <div class="page-container">
    <PageHeader icon="dashboard-active.svg" title="Дашборд" />
    <div class="page-content column">
      <tabs :tabsNames="tabsNames" @firstTabActive="firstTabActive = $event" />
      <div class="tab-content table" :class="{ 'tab-content__active-first': firstTabActive }">
        <TableComponent
          :columns="columns"
          :rows="tableRows_1"
          :title="'Клиенты'"
          :additionalRows="additionalRows_1"
          :show-additional-table-header="false"
          :isLightFontAdditional="true"
          :totalCount="totalCount"
          :pageSize="pageSize"
          :pageCount="pageCount"
          :currentPage="Number(currentPage)"
          :isLoading="loading"
          @downloadXSLX="downloadXSLX"
          @regetData="regetData($event)"
          :noData="noData"
          :actionType="'accountantDashboardActions'"
        />
        <!--        <table-comp></table-comp>-->
      </div>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapMutations, mapState } from 'vuex'
  import Tabs from '@/components/tabs.vue'
  import TableComponent from '@/components/table.vue'
  import TableComp from '@/components/tableComp.vue'

  export default {
    name: 'ExecutiveDashboard',
    components: { TableComp, TableComponent, Tabs },
    data: () => ({
      firstTabActive: true,
      tabsNames: [
        { name: 'Дашборд руководителя', linkName: 'admin.dashboard.executive' },
        { name: 'Дашборд бухгалтера', linkName: 'admin.dashboard.accountant' },
        { name: 'Оперативный отчет', linkName: 'admin.dashboard.operative' }
      ],
      tableColumns_1: [
        {
          name: 'Наименование клиента',
          field: 'client_name',
          filter_name: 'users_ids[]',
          filter_value: '',
          order: '',
          multiple: true
        },
        {
          name: 'Текущий\n' + 'баланс, р.',
          field: 'balance_format',
          fieldDefault: 'balance',
          filter_name: 'balance',
          filter_value: '',
          order: '',
          balance_field: true,
          filter_name_from: 'balance_from',
          filter_value_from: '',
          filter_name_to: 'balance_to',
          filter_value_to: '',
          from_to_fields: true
        },
        {
          name: 'Могут уходить\n' + 'в минус\n' + 'с учетом КЛ, р.',
          field: 'go_into_minus_format',
          fieldDefault: 'go_into_minus',
          filter_name: 'go_into_minus',
          filter_value: '',
          order: '',
          filter_name_from: 'go_into_minus_from',
          filter_value_from: '',
          filter_name_to: 'go_into_minus_to',
          filter_value_to: '',
          from_to_fields: true
        },
        // {
        //   name: 'Кредитный лимит',
        //   field: 'limit',
        //   filter_name: 'limit',
        //   filter_value: '',
        //   order: '',
        // },
        {
          name: 'Отсрочка, дней',
          field: 'delay',
          filter_name: 'delay',
          filter_value: '',
          order: '',
          filter_name_from: 'delay_from',
          filter_value_from: '',
          filter_name_to: 'delay_to',
          filter_value_to: '',
          from_to_fields: true
        },
        {
          name: 'Сальдо\n' + 'на начало\n' + 'года, р.',
          field: 'saldo',
          filter_name: 'saldo',
          filter_value: '',
          order: '',
          filter_name_from: 'saldo_from',
          filter_value_from: '',
          filter_name_to: 'saldo_to',
          filter_value_to: '',
          from_to_fields: true
        },
        {
          name: 'Оборот\n' + 'за текущий\n' + 'год, р.',
          field: 'payment_current_year',
          filter_name: 'payment_current_year',
          filter_value: '',
          order: '',
          filter_name_from: 'payment_current_year_from',
          filter_value_from: '',
          filter_name_to: 'payment_current_year_to',
          filter_value_to: '',
          from_to_fields: true
        },
        {
          name: 'Оборот\n' + 'за текущий\n' + 'месяц, р.',
          field: 'payment_current_month',
          filter_name: 'payment_current_month',
          filter_value: '',
          order: '',
          filter_name_from: 'payment_current_month_from',
          filter_value_from: '',
          filter_name_to: 'payment_current_month_to',
          filter_value_to: '',
          from_to_fields: true
        },
        {
          name: 'Потребление\n' + 'за текущий год\n' + '(тип топлива n), л.',
          field: 'current_year',
          filter_name: 'current_year',
          filter_value: '',
          order: '',
          fuel_type: true,
          multiple: true
        },
        {
          name: 'Потребление\n' + 'за текущий месяц\n' + '(тип топлива n), л.',
          field: 'current_month',
          filter_name: 'current_month',
          filter_value: '',
          order: '',
          fuel_type: true,
          multiple: true
        },
        {
          name: 'Менеджер',
          field: 'manager',
          filter_name: 'manager_ids[]',
          filter_value: '',
          order: '',
          multiple: true
        },
        {
          name: 'Кредитный лимит, р.',
          field: 'limit',
          filter_name: 'limit',
          filter_value: '',
          order: '',
          surety: true,
          filter_name_from: 'limit_from',
          filter_value_from: '',
          filter_name_to: 'limit_to',
          filter_value_to: '',
          from_to_fields: true
        }
      ],
      tableRows_1: [
        {
          client_name: 'ООО “Юнион Лаб”',
          current_balance: '500 000',
          minus: '-100 000',
          credit_limit: '1 200 000',
          postponement: '12',
          beginning_balance: '-1 032 583,60',
          year_turnover: '-1 032 583,60',
          month_turnover: '600 000',
          year_strait: '500',
          month_strait: '50',
          manager: 'Тимофей Петрович Иванов',
          guarantee: '120 000',
          green: { current_balance: true }
        },
        {
          client_name: 'ООО “Юнион Лаб”',
          current_balance: '-500 000',
          minus: '-100 000',
          credit_limit: '1 200 000',
          postponement: '12',
          beginning_balance: '-1 032 583,60',
          year_turnover: '-1 032 583,60',
          month_turnover: '600 000',
          year_strait: '500',
          month_strait: '50',
          manager: 'Тимофей Петрович Иванов',
          guarantee: '120 000',
          red: { current_balance: true }
        }
      ],
      additionalRows_1: [
        {
          client_name: 'Итого',
          balance: '',
          go_into_minus: '',
          delay: '',
          saldo: '',
          payment_current_year: '',
          payment_current_month: '',
          current_year: '',
          current_month: '',
          manager: '',
          limit: ''
        }
      ],
      noData: false,
      loading: false,
      totalCount: 0,
      pageSize: 0,
      pageCount: 0,
      currentPage: 0
    }),
    watch: {
      async accountant_dashboard(val) {
        await this.setTablesData()
      }
    },
    computed: {
      ...mapState({
        userRole: (state) => state.user.userRole,
        columns: (state) => state.popups.columns,
        accountant_dashboard: (state) => state.admin.accountant_dashboard
      })
    },
    methods: {
      ...mapActions({
        getAccountantDashboard: 'getAccountantDashboard',
        downloadXLSXFile: 'downloadXLSXFile',
        getFuelTypesReq: 'getFuelTypes'
      }),
      ...mapMutations({
        setColumns: 'SET_COLUMNS',
        setColumns_2: 'SET_COLUMNS_2',
        setPaginationFilters: 'SET_PAGINATION_FILTERS',
        resetPaginationFilters: 'RESET_PAGINATION_FILTERS'
      }),
      setFormatPrice(val) {
        let p;
        if (String(val).includes('.')) {
          p = val.split(".");
          return p[0].split("").reverse().reduce(function(acc, val, i, orig) {
            return val + (val !== "-" && i && !(i % 3) ? " " : "") + acc;
          }, "") + '.' + p[1];
        } else {
          p = String(val)
          return p.split("").reverse().reduce(function(acc, val, i, orig) {
            return val + (val !== "-" && i && !(i % 3) ? " " : "") + acc;
          }, "");
        }
      },
      async regetData(data) {
        console.log(data)
        this.loading = true

        let args = [
          {
            name: 'page',
            value: data.pageNumber
          },
          {
            name: 'page_size',
            value: data.size
          }
        ]

        if (Math.ceil(this.totalCount / data.size) < data.pageNumber) {
          args[0].value = 1
        }

        this.setPaginationFilters(args)

        await this.getAccountantDashboard()
      },
      async downloadXSLX() {
        this.$store.commit('SET_DOWNLOAD_LOADING', true)
        await this.getAccountantDashboard(true)
        this.$store.commit('SET_DOWNLOAD_LOADING', false)
      },
      async setTablesData() {
        this.totalCount = this.accountant_dashboard.total_count
        this.pageSize = this.accountant_dashboard.page_size
        this.pageCount = this.accountant_dashboard.page_count
        this.currentPage = this.accountant_dashboard.current_page
        let dashboardData = []

        for (let i = 0; i < this.accountant_dashboard.data.length; i++) {
          let data = this.accountant_dashboard.data[i]
          data.actionType = 'accountantDashboardActions'
          data.balance_format = this.setFormatPrice(Number(data.balance).toFixed(2))
          data.go_into_minus_format = this.setFormatPrice(Number(data.go_into_minus).toFixed(2))
          data.saldo = this.setFormatPrice(Number(data.saldo).toFixed(2))
          data.payment_current_year = this.setFormatPrice(Number(data.payment_current_year).toFixed(2))
          data.payment_current_month = this.setFormatPrice(Number(data.payment_current_month).toFixed(2))
          data.limit = this.setFormatPrice(Number(data.limit).toFixed(2))

          dashboardData.push(data)
        }

        this.tableRows_1 = dashboardData

        this.additionalRows_1[0].payment_current_year = this.setFormatPrice(this.accountant_dashboard.total.total_payment_current_year.toFixed(2))
        this.additionalRows_1[0].payment_current_month = this.setFormatPrice(this.accountant_dashboard.total.total_payment_current_month.toFixed(2))
        this.additionalRows_1[0].current_year = this.setFormatPrice(this.accountant_dashboard.total.total_current_year.toFixed(2))
        this.additionalRows_1[0].current_month = this.setFormatPrice(this.accountant_dashboard.total.total_current_month.toFixed(2))
        this.additionalRows_1[0].saldo = this.setFormatPrice(this.accountant_dashboard.total.total_saldo.toFixed(2))

        if (!this.tableRows_1 || this.tableRows_1.length === 0) {
          this.noData = true
        } else {
          this.noData = false
        }

        if (this.columns) {
          this.setColumns(this.columns)
        } else {
          this.setColumns(this.tableColumns_1)
        }

        this.loading = false
      }
    },
    async mounted() {
      this.setColumns(null)
      this.setColumns_2(null)

      // this.resetPaginationFilters()

      await this.getFuelTypesReq()

      // await this.getAccountantDashboard()
      let data = {
        size: 100,
        pageNumber: 1
      }

      this.regetData(data)
    }
  }
</script>

<style scoped lang="scss">
  @import '../../styles/variables';

  .page-content {
    display: flex;

    &.column {
      flex-direction: column;
    }
  }

  .tab-content {
    &.offset {
      margin-top: 30px;
    }
  }
</style>
