import * as type from './types'
import actions from './actions'
import api from '../../../api'

const state = {
  myUser: {},
  menuShown: true,
  notifications: null,
  totalNotificationsCount: 0,
  userNotificationSettings: null,
  adminNotificationSettings: null,
  downloadLoading: false
}

const mutations = {
  SET_DOWNLOAD_LOADING(state, payload) {
    state.downloadLoading = payload
  },
  [type.GET_MY_USER](state, payload) {
    state.myUser = payload
  },
  [type.TOGGLE_MENU](state, payload) {
    state.menuShown = payload
  },
  [type.SET_NOTIFICATIONS](state, payload) {
    state.notifications = payload
  },
  [type.SET_TOTAL_NOTIFICATIONS_COUNT](state, payload) {
    state.totalNotificationsCount = payload
  },
  [type.SET_USER_NOTIFICATION_SETTINGS](state, payload) {
    state.userNotificationSettings = payload
  },
  [type.SET_ADMIN_NOTIFICATION_SETTINGS](state, payload) {
    state.adminNotificationSettings = payload
  }
}

const getters = {}

export default {
  state,
  mutations,
  actions,
  getters
}
