import * as type from './types';
import api from '../../../api';
import login from "@/Pages/Login.vue";

import {getFinalUrl, getFinalUrl_2} from '@/utils/url_formatting'
import {SET_DEBT_REPORT} from "@/store/modules/customer/types";

const actions = {
    uploadTransactionsFile({commit, dispatch}, data) {
        console.log(data)
        return new Promise((resolve, reject) => {
            api.post(`transaction/upload-transactions`, data, {
                headers: { "Content-Type": "multipart/form-data" }
            })
                .then(res => {
                    if (res.data.status && res.data.status === 200) {
                        //dispatch('getAllUsers');
                        dispatch('showError', { errorMessage: res.data.msg || res.data.message, isSuccess: true })
                    } else {
                        //commit('SHOW_ADD_NEW_USER', false)
                        // dispatch('showError', { errorMessage: res.data.msg || res.data.message })
                      let errorsText = '.<br>';
                      if (res.data.data && res.data.data.length > 0) {
                        res.data.data.forEach(item => {
                          errorsText += `${item.message}: ${item.data['Дата']}.<br>`
                        })
                        dispatch('showError', { errorMessage: (res.data.msg || res.data.message) + errorsText })
                      } else {
                        dispatch('showError', { errorMessage: res.data.msg || res.data.message })
                      }
                    }
                    commit('getTransactionFiles');
                    resolve(res);
                })
                .catch(error => {
                    console.log('Problem', error.message);
                    //commit('SHOW_ADD_NEW_USER', false)
                   dispatch('showError', { errorMessage: error.response.data.message || error.message })
                    reject(error);
                });
        })
    },
    uploadFiles({commit, dispatch}, data) {
        console.log(data)
        return new Promise((resolve, reject) => {
            api.post(`file/upload-files`, data, {
                headers: { "Content-Type": "multipart/form-data" }
            })
                .then(res => {
                    if (res.data.status && res.data.status === 200) {
                        dispatch('getTransactionFiles');
                        dispatch('showError', { errorMessage: res.data.msg || res.data.message, isSuccess: true })
                    } else {
                        //commit('SHOW_ADD_NEW_USER', false)
                        dispatch('showError', { errorMessage: res.data.msg || res.data.message })
                    }
                    resolve(res);
                })
                .catch(error => {
                    console.log('Problem', error.message);
                    //commit('SHOW_ADD_NEW_USER', false)
                   dispatch('showError', { errorMessage: error.response.data.message || error.message })
                    reject(error);
                });
        })
    },
    uploadPayments({commit, dispatch}, data) {
        console.log(data)
        return new Promise((resolve, reject) => {
            api.post(`payment/upload-payments`, data, {
                headers: { "Content-Type": "multipart/form-data" }
            })
                .then(res => {
                  dispatch('getPayments');
                    if (res.data.status && res.data.status === 200) {
                        let errorsText = '';
                        if (typeof res.data.message !== 'string' && res.data.message && res.data.message.length > 0) {
                          res.data.message.forEach(item => {
                            errorsText += `${item.message}. ${item?.data}.<br>`
                          })
                          dispatch('showError', { errorMessage: errorsText, isSuccess: true })
                        } else {
                          dispatch('showError', { errorMessage: res.data.msg || res.data.message, isSuccess: true })
                        }
                    } else {
                        //commit('SHOW_ADD_NEW_USER', false)
                        let errorsText = '';
                        if (typeof res.data.message !== 'string' && res.data.message && res.data.message.length > 0) {
                          res.data.message.forEach(item => {
                            errorsText += `${item.message}. ${item?.data}.<br>`
                          })
                          dispatch('showError', { errorMessage: (res.data.msg || res.data.message) + errorsText })
                        } else {
                          dispatch('showError', { errorMessage: res.data.msg || res.data.message, isSuccess: true })
                        }
                    }
                    resolve(res);
                })
                .catch(error => {
                  dispatch('getPayments');
                    console.log('Problem', error.message);
                    //commit('SHOW_ADD_NEW_USER', false)
                   dispatch('showError', { errorMessage: error.response.data.message || error.message })
                    reject(error);
                });
        })
    },
    uploadFuelPrices({commit, dispatch}, data) {
        console.log(data)
        return new Promise((resolve, reject) => {
            api.post(`file/upload-fuel-prices`, data, {
                headers: { "Content-Type": "multipart/form-data" }
            })
                .then(res => {
                    if (res.data.status && res.data.status === 200) {
                        dispatch('getPricesFiles');
                        commit('SHOW_UPLOAD_PRICES', false)
                        dispatch('showError', { errorMessage: res.data.msg || res.data.message, isSuccess: true })
                    } else {
                        commit('SHOW_UPLOAD_PRICES', false)
                        dispatch('showError', { errorMessage: res.data.msg || res.data.message })
                    }
                    resolve(res);
                })
                .catch(error => {
                    console.log('Problem', error.message);
                    commit('SHOW_UPLOAD_PRICES', false)
                   dispatch('showError', { errorMessage: error.response.data.message || error.message })
                    reject(error);
                });
        })
    },
    uploadFuelPricesGold({commit, dispatch}, data) {
        console.log(data)
        return new Promise((resolve, reject) => {
            api.post(`/gold-gas/upload-file`, data, {
                headers: { "Content-Type": "multipart/form-data" }
            })
                .then(res => {
                    if (res.data.status && res.data.status === 200) {
                        dispatch('getPricesGoldFiles');
                        commit('SHOW_UPLOAD_PRICES', false)
                        dispatch('showError', { errorMessage: res.data.msg || res.data.message, isSuccess: true })
                    } else {
                        commit('SHOW_UPLOAD_PRICES', false)
                        dispatch('showError', { errorMessage: res.data.msg || res.data.message })
                    }
                    resolve(res);
                })
                .catch(error => {
                    console.log('Problem', error.message);
                    commit('SHOW_UPLOAD_PRICES', false)
                   dispatch('showError', { errorMessage: error.response.data.message || error.message })
                    reject(error);
                });
        })
    },
    uploadFuelPricesPartner({commit, dispatch}, data) {
        console.log(data)
        return new Promise((resolve, reject) => {
            api.post(`/partner-gas/upload-file`, data, {
                headers: { "Content-Type": "multipart/form-data" }
            })
                .then(res => {
                    if (res.data.status && res.data.status === 200) {
                        dispatch('getPricesPartnerFiles');
                        commit('SHOW_UPLOAD_PRICES', false)
                        dispatch('showError', { errorMessage: res.data.msg || res.data.message, isSuccess: true })
                    } else {
                        commit('SHOW_UPLOAD_PRICES', false)
                        dispatch('showError', { errorMessage: res.data.msg || res.data.message })
                    }
                    resolve(res);
                })
                .catch(error => {
                    console.log('Problem', error.message);
                    commit('SHOW_UPLOAD_PRICES', false)
                   dispatch('showError', { errorMessage: error.response.data.message || error.message })
                    reject(error);
                });
        })
    },
    bunchUploadCardsFile({commit, dispatch}, data) {
        console.log(data)
        return new Promise((resolve, reject) => {
            api.post(`card/upload-cards`, data, {
                headers: { "Content-Type": "multipart/form-data" }
            })
                .then(res => {
                    if (res.data.status && res.data.status === 200) {
                        //dispatch('getAllUsers');
                        dispatch('showError', { errorMessage: res.data.msg || res.data.message, isSuccess: true })
                        dispatch('getCards');
                    } else {
                        let errorsText = '.<br>';
                        if (res.data.data && res.data.data.length > 0 && res.data.data[0].length > 0) {
                          res.data.data[0].forEach(item => {
                            errorsText += `${item['0']}: ${item['1']}.<br>`
                          })
                          dispatch('showError', { errorMessage: (res.data.msg || res.data.message) + errorsText })
                        } else {
                          dispatch('showError', { errorMessage: res.data.msg || res.data.message })
                        }
                    }
                    resolve(res);
                })
                .catch(error => {
                    console.log('Problem', error.message);
                   dispatch('showError', { errorMessage: error.response.data.message || error.message })
                    reject(error);
                });
        })
    },
    deleteCard({commit, dispatch}, number) {
        return new Promise((resolve, reject) => {
            api.delete(`card/${number}/delete-card`)
                .then(res => {
                    if (res.data.status && res.data.status === 200) {
                        commit('SHOW_SUSPEND_CARD', false)
                        dispatch('showError', { errorMessage: res.data.msg || res.data.message, isSuccess: true })
                        dispatch('getCards');
                    } else {
                        commit('SHOW_SUSPEND_CARD', false)
                        dispatch('showError', { errorMessage: res.data.msg || res.data.message })
                    }
                    resolve(res);
                })
                .catch(error => {
                    console.log('Problem', error.message);
                    commit('SHOW_SUSPEND_CARD', false)
                   dispatch('showError', { errorMessage: error.response.data.message || error.message })
                    reject(error);
                });
        })
    },
    bunchChangeCardsStatus({commit, dispatch}, data) {
        return new Promise((resolve, reject) => {
            api.post(`card/bunch-unlock-cards`, data)
                .then(res => {
                    if (res.data.status && res.data.status === 200) {
                        commit('SHOW_BUNCH_CHANGE_CARD_STATUS', false)
                        commit('SHOW_SUSPEND_CARD', false)
                        dispatch('showError', { errorMessage: res.data.msg || res.data.message, isSuccess: true })
                        dispatch('getCards');
                    } else {
                        commit('SHOW_BUNCH_CHANGE_CARD_STATUS', false)
                        commit('SHOW_SUSPEND_CARD', false)
                        dispatch('showError', { errorMessage: res.data.msg || res.data.message })
                    }
                    resolve(res);
                })
                .catch(error => {
                    console.log('Problem', error.message);
                    commit('SHOW_BUNCH_CHANGE_CARD_STATUS', false)
                    commit('SHOW_SUSPEND_CARD', false)
                   dispatch('showError', { errorMessage: error.response.data.message || error.message })
                    reject(error);
                });
        })
    },
    bunchSetCardsCustomer({commit, dispatch}, data) {
        return new Promise((resolve, reject) => {
            api.post(`/card/bunch-set-cards-customer`, data)
                .then(res => {
                    if (res.data.status && res.data.status === 200) {
                        commit('SET_SHOW_CARDS_FOR_CLIENT_POPUP', false)
                        dispatch('showError', { errorMessage: res.data.msg || res.data.message, isSuccess: true })
                        dispatch('getCustomers');
                    } else {
                        commit('SET_SHOW_CARDS_FOR_CLIENT_POPUP', false)
                        dispatch('showError', { errorMessage: res.data.msg || res.data.message })
                    }
                    resolve(res);
                })
                .catch(error => {
                    console.log('Problem', error.message);
                    commit('SET_SHOW_CARDS_FOR_CLIENT_POPUP', false)
                   dispatch('showError', { errorMessage: error.response.data.message || error.message })
                    reject(error);
                });
        })
    },
    bunchSetCardsLimit({commit, dispatch, state}, data) {
        return new Promise((resolve, reject) => {
            api.post(`card/bunch-set-cards-limit`, data)
                .then(res => {
                    if (Array.isArray(res.data)) {
                      let errorText = '';
                      res.data.forEach(item => {
                        errorText += `${item.card_number}: ${item.message}<br>`
                      })
                      commit('SHOW_SET_CARD_LIMIT', false)
                      commit('SHOW_SET_CARD_LIMIT_CUSTOMER', false)
                      dispatch('getCards');
                      if (Number(localStorage.getItem('role')) !== 3) {
                        dispatch('getCustomers');
                      }
                      dispatch('showError', {errorMessage: errorText})
                    } else {
                      if (res.data.status && res.data.status === 200) {
                        commit('SHOW_SET_CARD_LIMIT', false)
                        commit('SHOW_SET_CARD_LIMIT_CUSTOMER', false)
                        dispatch('showError', {errorMessage: res.data.msg || res.data.message, isSuccess: true})
                        dispatch('getCards');
                        if (Number(localStorage.getItem('role')) !== 3) {
                          dispatch('getCustomers');
                        }
                      } else {
                        commit('SHOW_SET_CARD_LIMIT', false)
                        commit('SHOW_SET_CARD_LIMIT_CUSTOMER', false)
                        dispatch('showError', {errorMessage: res.data.msg || res.data.message})
                      }
                    }
                    resolve(res);
                })
                .catch(error => {
                    console.log('Problem', error.message);
                    commit('SHOW_SET_CARD_LIMIT', false)
                    commit('SHOW_SET_CARD_LIMIT_CUSTOMER', false)
                   dispatch('showError', { errorMessage: error.response.data.message || error.message })
                    reject(error);
                });
        })
    },
    async removeCustomer({commit, dispatch}, id) {
        return await new Promise((resolve, reject) => {
            api.delete(`customer/${id}/delete-customer`)
                .then(res => {
                    if (res.data.status && res.data.status === 200) {
                        commit('SHOW_REMOVE_CLIENT', false)
                        dispatch('showError', { errorMessage: res.data.msg || res.data.message, isSuccess: true })
                        dispatch('getCustomers');
                    } else {
                        commit('SHOW_REMOVE_CLIENT', false)
                        dispatch('showError', { errorMessage: res.data.msg || res.data.message })
                    }
                    resolve(res);
                })
                .catch(error => {
                    console.log('Problem', error.message);
                    commit('SHOW_REMOVE_CLIENT', false)
                   dispatch('showError', { errorMessage: error.response.data.message || error.message })
                    reject(error);
                });
        })
    },
    addNewCard({commit, dispatch}, data) {
        return new Promise((resolve, reject) => {
            api.post(`card/create-card`, data)
                .then(res => {
                    if (res.data.status && res.data.status === 200) {
                        commit('SHOW_ADD_CARD', false)
                      commit('SET_EDIT_CART_DATA_POPUP', null)
                        dispatch('showError', { errorMessage: res.data.msg || res.data.message, isSuccess: true })
                        dispatch('getCards');
                    } else {
                        commit('SHOW_ADD_CARD', false)
                        dispatch('showError', { errorMessage: res.data.msg || res.data.message })
                    }
                    resolve(res);
                })
                .catch(error => {
                    console.log('Problem', error.message);
                    commit('SHOW_ADD_CARD', false)
                   dispatch('showError', { errorMessage: error.response.data.message || error.message })
                    reject(error);
                });
        })
    },
  editCard({commit, dispatch}, data) {
    return new Promise((resolve, reject) => {
      api.put(`card/${data.id}/edit-card`, data.data)
        .then(res => {
          if (res.data.status && res.data.status === 200) {
            commit('SET_SHOW_EDIT_CARD_POPUP', false)
            commit('SHOW_ADD_CARD', false)
            commit('SET_EDIT_CART_DATA_POPUP', null)
            commit('SET_DATA_EDIT_CARD_POPUP', null)
            dispatch('showError', { errorMessage: res.data.msg || res.data.message, isSuccess: true })
            dispatch('getCards');
          } else {
            commit('SET_SHOW_EDIT_CARD_POPUP', false)
            commit('SHOW_ADD_CARD', false)
            commit('SET_EDIT_CART_DATA_POPUP', null)
            commit('SET_DATA_EDIT_CARD_POPUP', null)
            dispatch('showError', { errorMessage: res.data.msg || res.data.message })
            dispatch('getCards');
          }
          resolve(res);
        })
        .catch(error => {
          console.log('Problem', error.message);
          commit('SET_SHOW_EDIT_CARD_POPUP', false)
          commit('SHOW_ADD_CARD', false)
          commit('SET_EDIT_CART_DATA_POPUP', null)
          commit('SET_DATA_EDIT_CARD_POPUP', null)
          dispatch('showError', { errorMessage: error.response.data.message || error.message })
          reject(error);
        });
    })
  },
    editCustomer({commit, dispatch}, data) {
        return new Promise((resolve, reject) => {
            api.put(`customer/${data.id}/edit-customer`, data.form_data, {
                headers: { "Content-Type": "multipart/form-data" }
            })
                .then(res => {
                    if (res.data.status && res.data.status === 200) {
                        commit('SHOW_EDIT_CLIENT', false)
                        commit('SHOW_EDIT_USER', false)
                        dispatch('showError', { errorMessage: res.data.msg || res.data.message, isSuccess: true })
                        dispatch('getCustomers');
                        dispatch('getAllUsers');
                    } else {
                        commit('SHOW_EDIT_CLIENT', false)
                        commit('SHOW_EDIT_USER', false)
                        dispatch('showError', { errorMessage: res.data.msg || res.data.message })
                    }
                    resolve(res);
                })
                .catch(error => {
                    console.log('Problem', error.message);
                    commit('SHOW_EDIT_CLIENT', false)
                    commit('SHOW_EDIT_USER', false)
                    dispatch('showError', { errorMessage: error.response.data.message || error.message })
                    reject(error);
                });
            commit('SET_SURETY_FILES_TO_ADD', [])
            commit('SET_SURETY_FILES_TO_REMOVE', [])
            commit('SET_SUPPLY_FILES_TO_ADD', [])
            commit('SET_SUPPLY_FILES_TO_REMOVE', [])
        })
    },
    async getCards({commit, dispatch, rootState}, getFile = false) {
        return new Promise(async (resolve, reject) => {
            let url = `card/get-cards`
            let finalUrl = await getFinalUrl(url, rootState)
            if (getFile) {
                url = `card/get-cards?returnFile=true`
                finalUrl = await getFinalUrl(url, rootState, false)
              let dateNow = new Date();
              let dd = String(dateNow.getDate()).padStart(2, '0');
              let mm = String(dateNow.getMonth() + 1).padStart(2, '0'); //January is 0!
              let yyyy = dateNow.getFullYear();
              api.get(`${finalUrl}`, {responseType: 'blob'})
                .then(res => {
                  const url = window.URL.createObjectURL(new Blob([res.data]));
                  const link = document.createElement('a');
                  link.href = url;
                  link.setAttribute('download', `карты--${dd + '.' + mm + '.' + yyyy}.xlsx`); //or any other extension
                  document.body.appendChild(link);
                  link.click();
                  link.remove();
                  resolve(res);
                })
                .catch(error => {
                  console.log('Problem', error.message);
                  dispatch('showError', { errorMessage: error.response.data.message || error.message })
                  reject(error);
                });
            } else {
              api.get(finalUrl)
                .then(res => {
                  console.log(res)
                  if (res.status && res.status === 200) {
                    commit('SET_CARDS', res.data)
                  } else {
                    dispatch('showError', {errorMessage: res.data.msg || res.data.message})
                    commit('SET_TABLE_FILTER_POPUP', null)
                    commit('SET_TABLE_FILTER_POPUP_2', null)
                    commit('SET_ACTION_POPUP', null)
                  }
                  resolve(res);
                })
                .catch(error => {
                  console.log('Problem', error.message);
                  commit('SET_TABLE_FILTER_POPUP', null)
                  commit('SET_TABLE_FILTER_POPUP_2', null)
                  commit('SET_ACTION_POPUP', null)
                  dispatch('showError', {errorMessage: error.response.data.message || error.message})
                  reject(error);
                });
            }
        })
    },
    async getPayments({commit, dispatch, rootState}, getFile = false) {
        return new Promise(async (resolve, reject) => {
            let url = `payment/get-payments`
            let finalUrl = await getFinalUrl(url, rootState)

            if (getFile) {
              url = `payment/get-payments?returnFile=true`
                finalUrl = await getFinalUrl(url, rootState, false)
              let dateNow = new Date();
              let dd = String(dateNow.getDate()).padStart(2, '0');
              let mm = String(dateNow.getMonth() + 1).padStart(2, '0'); //January is 0!
              let yyyy = dateNow.getFullYear();
              api.get(`${finalUrl}`, {responseType: 'blob'})
                .then(res => {
                  const url = window.URL.createObjectURL(new Blob([res.data]));
                  const link = document.createElement('a');
                  link.href = url;
                  link.setAttribute('download', `платежи--${dd + '.' + mm + '.' + yyyy}.xlsx`); //or any other extension
                  document.body.appendChild(link);
                  link.click();
                  link.remove();
                  resolve(res);
                })
                .catch(error => {
                  console.log('Problem', error.message);
                  dispatch('showError', { errorMessage: error.response.data.message || error.message })
                  reject(error);
                });
            } else {
              api.get(finalUrl)
                .then(res => {
                  console.log(res)
                  if (res.status && res.status === 200) {
                    commit('SET_PAYMENTS', res.data)
                  } else {
                    dispatch('showError', {errorMessage: res.data.msg || res.data.message})
                    commit('SET_TABLE_FILTER_POPUP', null)
                    commit('SET_TABLE_FILTER_POPUP_2', null)
                    commit('SET_ACTION_POPUP', null)
                  }
                  resolve(res);
                })
                .catch(error => {
                  console.log('Problem', error.message);
                  dispatch('showError', {errorMessage: error.response.data.message || error.message})
                  commit('SET_TABLE_FILTER_POPUP', null)
                  commit('SET_TABLE_FILTER_POPUP_2', null)
                  commit('SET_ACTION_POPUP', null)
                  reject(error);
                });
            }
        })
    },
    async getManagerDashboard({commit, dispatch, rootState}, getFile = false) {
        let url = `dashboard/manager-dashboard`
        let finalUrl = await getFinalUrl(url, rootState)

        return new Promise(async (resolve, reject) => {
            if (getFile) {
              url = `dashboard/manager-dashboard?returnFile=true`
              finalUrl = await getFinalUrl(url, rootState, false)
              let dateNow = new Date();
              let dd = String(dateNow.getDate()).padStart(2, '0');
              let mm = String(dateNow.getMonth() + 1).padStart(2, '0'); //January is 0!
              let yyyy = dateNow.getFullYear();
              api.get(`${finalUrl}`, {responseType: 'blob'})
                .then(res => {
                  const url = window.URL.createObjectURL(new Blob([res.data]));
                  const link = document.createElement('a');
                  link.href = url;
                  link.setAttribute('download', `клиенты--${dd + '.' + mm + '.' + yyyy}.xlsx`); //or any other extension
                  document.body.appendChild(link);
                  link.click();
                  link.remove();
                  resolve(res);
                })
                .catch(error => {
                  console.log('Problem', error.message);
                  dispatch('showError', { errorMessage: error.response.data.message || error.message })
                  reject(error);
                });
            } else {
              api.get(finalUrl)
                .then(res => {
                  console.log(res)
                  if (res.status && res.status === 200) {
                    commit('SET_MANAGER_DASHBOARD', res.data)
                  } else {
                    dispatch('showError', {errorMessage: res.data.msg || res.data.message})
                    commit('SET_TABLE_FILTER_POPUP', null)
                    commit('SET_TABLE_FILTER_POPUP_2', null)
                    commit('SET_ACTION_POPUP', null)
                  }
                  resolve(res);
                })
                .catch(error => {
                  console.log('Problem', error.message);
                  commit('SET_TABLE_FILTER_POPUP', null)
                  commit('SET_TABLE_FILTER_POPUP_2', null)
                  commit('SET_ACTION_POPUP', null)
                  dispatch('showError', {errorMessage: error.response.data.message || error.message})
                  reject(error);
                });
            }
        })
    },
    async getAccountantDashboard({commit, dispatch, rootState}, getFile = false) {
        let url = `dashboard/balance-dashboard`
        let finalUrl = await getFinalUrl(url, rootState)

        return new Promise(async (resolve, reject) => {
            if (getFile) {
              url = `dashboard/balance-dashboard?returnFile=true`
              finalUrl = await getFinalUrl(url, rootState, false)
              let dateNow = new Date();
              let dd = String(dateNow.getDate()).padStart(2, '0');
              let mm = String(dateNow.getMonth() + 1).padStart(2, '0'); //January is 0!
              let yyyy = dateNow.getFullYear();
              api.get(`${finalUrl}`, {responseType: 'blob'})
                .then(res => {
                  const url = window.URL.createObjectURL(new Blob([res.data]));
                  const link = document.createElement('a');
                  link.href = url;
                  link.setAttribute('download', `клиенты--${dd + '.' + mm + '.' + yyyy}.xlsx`); //or any other extension
                  document.body.appendChild(link);
                  link.click();
                  link.remove();
                  resolve(res);
                })
                .catch(error => {
                  console.log('Problem', error.message);
                  dispatch('showError', { errorMessage: error.response.data.message || error.message })
                  reject(error);
                });
            } else {
              api.get(finalUrl)
                .then(res => {
                  console.log(res)
                  if (res.status && res.status === 200) {
                    commit('SET_ACCOUNTANT_DASHBOARD', res.data)
                  } else {
                    dispatch('showError', {errorMessage: res.data.msg || res.data.message})
                    commit('SET_TABLE_FILTER_POPUP', null)
                    commit('SET_TABLE_FILTER_POPUP_2', null)
                    commit('SET_ACTION_POPUP', null)
                  }
                  resolve(res);
                })
                .catch(error => {
                  console.log('Problem', error.message);
                  commit('SET_TABLE_FILTER_POPUP', null)
                  commit('SET_TABLE_FILTER_POPUP_2', null)
                  commit('SET_ACTION_POPUP', null)
                  dispatch('showError', {errorMessage: error.response.data.message || error.message})
                  reject(error);
                });
            }
        })
    },
    async getOperationsReport({commit, dispatch, rootState}, getFile = false) {
        let url = `dashboard/operative-report`
        let finalUrl = await getFinalUrl_2(url, rootState)

        return new Promise(async (resolve, reject) => {
            if (getFile) {
              url = `dashboard/operative-report?returnFile=true`
              finalUrl = await getFinalUrl_2(url, rootState, false)
              let dateNow = new Date();
              let dd = String(dateNow.getDate()).padStart(2, '0');
              let mm = String(dateNow.getMonth() + 1).padStart(2, '0'); //January is 0!
              let yyyy = dateNow.getFullYear();
              api.get(`${finalUrl}`, {responseType: 'blob'})
                .then(res => {
                  const url = window.URL.createObjectURL(new Blob([res.data]));
                  const link = document.createElement('a');
                  link.href = url;
                  link.setAttribute('download', `оперативный-отчет--${dd + '.' + mm + '.' + yyyy}.xlsx`); //or any other extension
                  document.body.appendChild(link);
                  link.click();
                  link.remove();
                  resolve(res);
                })
                .catch(error => {
                  console.log('Problem', error.message);
                  dispatch('showError', { errorMessage: error.response.data.message || error.message })
                  reject(error);
                });
            } else {
              api.get(finalUrl)
                .then(res => {
                  console.log(res)
                  if (res.status && res.status === 200) {
                    commit('SET_OPERATIONS_REPORT', res.data)
                  } else {
                    dispatch('showError', {errorMessage: res.data.msg || res.data.message})
                    commit('SET_TABLE_FILTER_POPUP', null)
                    commit('SET_TABLE_FILTER_POPUP_2', null)
                    commit('SET_ACTION_POPUP', null)
                  }
                  resolve(res);
                })
                .catch(error => {
                  console.log('Problem', error.message);
                  commit('SET_TABLE_FILTER_POPUP', null)
                  commit('SET_TABLE_FILTER_POPUP_2', null)
                  commit('SET_ACTION_POPUP', null)
                  dispatch('showError', {errorMessage: error.response.data.message || error.message})
                  reject(error);
                });
            }
        })
    },
    async getCustomers({commit, dispatch, rootState}, getFile = false) {
        let url = `customer/get-customers`
        let finalUrl = '';
        let type = Number(localStorage.getItem('role'));
        if (type === 1) {
          finalUrl = await getFinalUrl(url, rootState)
        } else {
          finalUrl = await getFinalUrl_2(url, rootState)
        }

        return new Promise(async (resolve, reject) => {
            if (getFile) {
              url = `customer/get-customers?returnFile=true`
                if (type === 1) {
                    finalUrl = await getFinalUrl(url, rootState, false)
                } else {
                    finalUrl = await getFinalUrl_2(url, rootState, false)
                }
              let dateNow = new Date();
              let dd = String(dateNow.getDate()).padStart(2, '0');
              let mm = String(dateNow.getMonth() + 1).padStart(2, '0'); //January is 0!
              let yyyy = dateNow.getFullYear();
              api.get(`${finalUrl}`, {responseType: 'blob'})
                .then(res => {
                  const url = window.URL.createObjectURL(new Blob([res.data]));
                  const link = document.createElement('a');
                  link.href = url;
                  link.setAttribute('download', `клиенты--${dd + '.' + mm + '.' + yyyy}.xlsx`); //or any other extension
                  document.body.appendChild(link);
                  link.click();
                  link.remove();
                  resolve(res);
                })
                .catch(error => {
                  console.log('Problem', error.message);
                  dispatch('showError', { errorMessage: error.response.data.message || error.message })
                  reject(error);
                });
            } else {
              api.get(finalUrl)
                .then(res => {
                  console.log(res)
                  if (res.status && res.status === 200) {
                    commit('SET_CUSTOMERS', res.data)
                    // commit('SET_DATE_RANGE', '')
                  } else {
                    dispatch('showError', {errorMessage: res.data.msg || res.data.message})
                    commit('SET_TABLE_FILTER_POPUP', null)
                    commit('SET_TABLE_FILTER_POPUP_2', null)
                    commit('SET_ACTION_POPUP', null)
                    // commit('SET_DATE_RANGE', '')
                  }
                  resolve(res);
                })
                .catch(error => {
                  console.log('Problem', error.message);
                  commit('SET_TABLE_FILTER_POPUP', null)
                  commit('SET_TABLE_FILTER_POPUP_2', null)
                  commit('SET_ACTION_POPUP', null)
                  // commit('SET_DATE_RANGE', '')
                  dispatch('showError', {errorMessage: error.response.data.message || error.message})
                  reject(error);
                });
            }
        })
    },
    async getReviseReport({commit, dispatch, rootState}, getFile = false) {
        let url = `dashboard/sverka-report`

        let finalUrl = await getFinalUrl(url, rootState)

        return new Promise(async (resolve, reject) => {
            if (getFile) {
              url = `dashboard/sverka-report?returnFile=true`
                finalUrl = await getFinalUrl(url, rootState, false)
                let dateNow = new Date();
                let dd = String(dateNow.getDate()).padStart(2, '0');
                let mm = String(dateNow.getMonth() + 1).padStart(2, '0'); //January is 0!
                let yyyy = dateNow.getFullYear();
                api.get(`${finalUrl}`, {responseType: 'blob'})
                    .then(res => {
                        const url = window.URL.createObjectURL(new Blob([res.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', `отчет-сверка--${dd + '.' + mm + '.' + yyyy}.xlsx`); //or any other extension
                        document.body.appendChild(link);
                        link.click();
                        link.remove();
                        resolve(res);
                    })
                    .catch(error => {
                        console.log('Problem', error.message);
                        dispatch('showError', { errorMessage: error.response.data.message || error.message })
                        reject(error);
                    });
            } else {
                api.get(finalUrl)
                    .then(res => {
                        console.log(res)
                        if (res.status && res.status === 200) {
                            commit('SET_REVISE_REPORT', res.data)
                        } else {
                            dispatch('showError', { errorMessage: res.data.msg || res.data.message })
                            commit('SET_TABLE_FILTER_POPUP', null)
                            commit('SET_TABLE_FILTER_POPUP_2', null)
                            commit('SET_ACTION_POPUP', null)
                        }
                        resolve(res);
                    })
                    .catch(error => {
                        console.log('Problem', error.message);
                        commit('SET_TABLE_FILTER_POPUP', null)
                        commit('SET_TABLE_FILTER_POPUP_2', null)
                        commit('SET_ACTION_POPUP', null)
                        dispatch('showError', { errorMessage: error.response.data.message || error.message })
                        reject(error);
                    });
            }
        })
    },
    async getCardStatuses({commit, dispatch}) {
        let url = `catalog/get-card-statuses`

        return new Promise((resolve, reject) => {
            api.get(url)
                .then(res => {
                    console.log(res)
                    if (res.status && res.status === 200) {
                        commit('SET_CARD_STATUSES', res.data)
                    } else {
                        dispatch('showError', { errorMessage: res.data.msg || res.data.message })
                        commit('SET_TABLE_FILTER_POPUP', null)
                        commit('SET_TABLE_FILTER_POPUP_2', null)
                        commit('SET_ACTION_POPUP', null)
                    }
                    resolve(res);
                })
                .catch(error => {
                    console.log('Problem', error.message);
                    commit('SET_TABLE_FILTER_POPUP', null)
                    commit('SET_TABLE_FILTER_POPUP_2', null)
                    commit('SET_ACTION_POPUP', null)
                   dispatch('showError', { errorMessage: error.response.data.message || error.message })
                    reject(error);
                });
        })
    },
    async getGoldStations({commit, dispatch}) {
        let url = `/catalog/get-gold-stations`

        return new Promise((resolve, reject) => {
            api.get(url)
                .then(res => {
                    console.log(res)
                    if (res.status && res.status === 200) {
                        commit('SET_GOLD_STATIONS', res.data)
                    } else {
                        dispatch('showError', { errorMessage: res.data.msg || res.data.message })
                    }
                    resolve(res);
                })
                .catch(error => {
                    console.log('Problem', error.message);
                   dispatch('showError', { errorMessage: error.response.data.message || error.message })
                    reject(error);
                });
        })
    },
    async getPartnerStations({commit, dispatch}) {
        let url = `/catalog/get-partner-stations`

        return new Promise((resolve, reject) => {
            api.get(url)
                .then(res => {
                    console.log(res)
                    if (res.status && res.status === 200) {
                        commit('SET_PARTNER_STATIONS', res.data)
                    } else {
                        dispatch('showError', { errorMessage: res.data.msg || res.data.message })
                    }
                    resolve(res);
                })
                .catch(error => {
                    console.log('Problem', error.message);
                   dispatch('showError', { errorMessage: error.response.data.message || error.message })
                    reject(error);
                });
        })
    },
    async getIntegrations({commit, dispatch}) {
        let url = `/catalog/get-integrations`

        return new Promise((resolve, reject) => {
            api.get(url)
                .then(res => {
                    console.log(res)
                    if (res.status && res.status === 200) {
                        commit('SET_INTEGRATIONS', res.data)
                    } else {
                        dispatch('showError', { errorMessage: res.data.msg || res.data.message })
                    }
                    resolve(res);
                })
                .catch(error => {
                    console.log('Problem', error.message);
                   dispatch('showError', { errorMessage: error.response.data.message || error.message })
                    reject(error);
                });
        })
    },
    async getPaymentIntegrations({commit, dispatch}) {
        let url = `/catalog/get-payment-integrations`

        return new Promise((resolve, reject) => {
            api.get(url)
                .then(res => {
                    console.log(res)
                    if (res.status && res.status === 200) {
                        commit('SET_PAYMENT_INTEGRATIONS', res.data)
                    } else {
                        dispatch('showError', { errorMessage: res.data.msg || res.data.message })
                    }
                    resolve(res);
                })
                .catch(error => {
                    console.log('Problem', error.message);
                   dispatch('showError', { errorMessage: error.response.data.message || error.message })
                    reject(error);
                });
        })
    },
    async getCustomerById({commit, dispatch, rootState}, data) {
        return new Promise((resolve, reject) => {
            api.get(`customer/${data.id}/get-customer`)
                .then(res => {
                    console.log(res)
                    if (res.status && res.status === 200) {
                        if (data.is_client) {
                            commit('SET_CLIENT', res.data)
                        }  else {
                            commit('SET_USER', res.data)
                        }
                    } else {
                        dispatch('showError', { errorMessage: res.data.msg || res.data.message })
                    }
                    resolve(res);
                })
                .catch(error => {
                    console.log('Problem', error.message);
                   dispatch('showError', { errorMessage: error.response.data.message || error.message })
                    reject(error);
                });
        })
    },
    async getTransactionFiles({commit, dispatch, rootState}) {
        let url = `transaction/get-transaction-files`
        let finalUrl = await getFinalUrl(url, rootState)

        return new Promise((resolve, reject) => {
            api.get(finalUrl)
                .then(res => {
                    if (res.status && res.status === 200) {
                        commit('SET_TRANSACTION_FILES', res.data)
                    } else {
                        dispatch('showError', { errorMessage: res.data.msg || res.data.message })
                    }
                    resolve(res);
                })
                .catch(error => {
                    console.log('Problem', error.message);
                    dispatch('showError', { errorMessage: error.response.data.message || error.message })
                    reject(error);
                });
        })
    },
    async editTransaction({commit, dispatch}, data) {
        return new Promise((resolve, reject) => {
            api.put(`transaction/${data.id}/update-transaction`, data.data)
                .then(res => {
                    if (res.data.status && res.data.status === 200) {
                        commit('SHOW_EDIT_TRANSACTION', false)
                        dispatch('showError', { errorMessage: res.data.msg || res.data.message, isSuccess: true })
                        dispatch('getTransactionReport');
                    } else {
                        commit('SHOW_EDIT_TRANSACTION', false)
                        dispatch('showError', { errorMessage: res.data.msg || res.data.message })
                    }
                    resolve(res);
                })
                .catch(error => {
                    console.log('Problem', error.message);
                    commit('SHOW_EDIT_TRANSACTION', false)
                    dispatch('showError', { errorMessage: error.response.data.message || error.message })
                    reject(error);
                });
        })
    },
    async updateTransactions({commit, dispatch, rootState}, data) {
        return new Promise((resolve, reject) => {
            api.get(`transaction/get-transaction-api-delk?start_date=${data.range.startDate}&end_date=${data.range.endDate}&integration=${data.integration}&update_mode=${data.update_mode}`)
            //api.post(`transaction/get-transaction-api-delk`, data)
                .then(res => {
                    if (res.status && res.status === 200) {
                        commit('SHOW_UPDATE_TRANSACTIONS', false)
                        dispatch('showError', { errorMessage: res.data.msg || res.data.message, isSuccess: true })
                      dispatch('getTransactionReport')
                    } else {
                        commit('SHOW_UPDATE_TRANSACTIONS', false)
                        // dispatch('showError', { errorMessage: res.data.msg || res.data.message })

                        let errorsText = '.<br>';
                        if (res.data.data && res.data.data.length > 0) {
                            res.data.data.forEach(item => {
                                errorsText += `${item.message}: ${item.data['Дата']}.<br>`
                            })
                            dispatch('showError', { errorMessage: (res.data.msg || res.data.message) + errorsText })
                        } else {
                            dispatch('showError', { errorMessage: res.data.msg || res.data.message })
                        }
                    }
                    resolve(res);
                })
                .catch(error => {
                    commit('SHOW_UPDATE_TRANSACTIONS', false)
                    console.log('Problem', error.message);
                    dispatch('showError', { errorMessage: error.response?.data?.message || error.message })
                    reject(error);
                });
        })
    },

    async editPayment({commit, dispatch}, data) {
        return new Promise((resolve, reject) => {
            api.put(`payment/${data.id}/edit-payment`, data.data)
                .then(res => {
                    if (res.status === 200 || res.data?.status === 200) {
                        commit('SHOW_EDIT_PAYMENT', false)
                        dispatch('showError', { errorMessage: res.data.msg || res.data.message, isSuccess: true })
                        dispatch('getPayments');
                    } else {
                        commit('SHOW_EDIT_PAYMENT', false)
                        dispatch('showError', { errorMessage: res.data.msg || res.data.message })
                    }
                    resolve(res);
                })
                .catch(error => {
                    console.log('Problem', error.message);
                    commit('SHOW_EDIT_PAYMENT', false)
                    dispatch('showError', { errorMessage: error.response.data.message || error.message })
                    reject(error);
                });
        })
    },
    async getPricesFiles({commit, dispatch, rootState}) {
        let url = `file/get-price-lists`
        let finalUrl = await getFinalUrl(url, rootState)

        return new Promise((resolve, reject) => {
            api.get(finalUrl)
                .then(res => {
                    if (res.status && res.status === 200) {
                        commit('SET_PRICES_FILES', res.data)
                    } else {
                        dispatch('showError', { errorMessage: res.data.msg || res.data.message })
                    }
                    resolve(res);
                })
                .catch(error => {
                    console.log('Problem', error.message);
                    dispatch('showError', { errorMessage: error.response?.data?.message || error.message })
                    reject(error);
                });
        })
    },
    async deletePriceFile({commit, dispatch}, id) {
        return new Promise((resolve, reject) => {
            api.delete(`/file/${id}/delete-price-list`)
                .then(res => {
                    if (res.data.status && res.data.status === 200) {
                        commit('SHOW_REMOVE_FILE', false)
                        dispatch('showError', { errorMessage: res.data.msg || res.data.message, isSuccess: true })
                        dispatch('getPricesFiles');
                    } else {
                        commit('SHOW_REMOVE_FILE', false)
                        dispatch('showError', { errorMessage: res.data.msg || res.data.message })
                    }
                    resolve(res);
                })
                .catch(error => {
                    console.log('Problem', error.message);
                    commit('SHOW_REMOVE_FILE', false)
                    dispatch('showError', { errorMessage: error.response.data.message || error.message })
                    reject(error);
                });
        })
    },
    async getPricesGoldFiles({commit, dispatch, rootState}) {
        let url = `/gold-gas/get-all-files`
        let finalUrl = await getFinalUrl(url, rootState)

        return new Promise((resolve, reject) => {
            api.get(finalUrl)
                .then(res => {
                    if (res.status && res.status === 200) {
                        commit('SET_PRICES_GOLD_FILES', res.data)
                    } else {
                        dispatch('showError', { errorMessage: res.data.msg || res.data.message })
                    }
                    resolve(res);
                })
                .catch(error => {
                    console.log('Problem', error.message);
                    dispatch('showError', { errorMessage: error.response?.data?.message || error.message })
                    reject(error);
                });
        })
    },
    async setEndPricesGold({commit, dispatch, rootState}, payload) {
        return new Promise((resolve, reject) => {
            api.post(`/gold-gas/set-end-date-file/${payload.id}`, { date: payload.date })
                .then(res => {
                    if (res.status && res.status === 200) {
                      commit('TOGGLE_OVERLAY', false)
                      commit('SHOW_END_PRICE_GOLD_DATE_POPUP', false)
                      commit('SET_END_PRICE_GOLD_DATE_OBJ', null)
                        dispatch('getPricesGoldFiles')
                    } else {
                        dispatch('showError', { errorMessage: res.data.msg || res.data.message })
                    }
                    resolve(res);
                })
                .catch(error => {
                    console.log('Problem', error.message);
                    dispatch('showError', { errorMessage: error.response?.data?.message || error.message })
                    reject(error);
                });
        })
    },
    async deletePriceGoldFile({commit, dispatch}, id) {
        return new Promise((resolve, reject) => {
            api.delete(`/gold-gas/delete-file/${id}`)
                .then(res => {
                    if (res.data.status && res.data.status === 200) {
                        commit('SHOW_REMOVE_FILE', false)
                        dispatch('showError', { errorMessage: res.data.msg || res.data.message, isSuccess: true })
                        dispatch('getPricesGoldFiles');
                    } else {
                        commit('SHOW_REMOVE_FILE', false)
                        dispatch('showError', { errorMessage: res.data.msg || res.data.message })
                    }
                    resolve(res);
                })
                .catch(error => {
                    console.log('Problem', error.message);
                    commit('SHOW_REMOVE_FILE', false)
                    dispatch('showError', { errorMessage: error.response.data.message || error.message })
                    reject(error);
                });
        })
    },
    async getPricesPartnerFiles({commit, dispatch, rootState}) {
        let url = `/partner-gas/get-all-files`
        let finalUrl = await getFinalUrl(url, rootState)

        return new Promise((resolve, reject) => {
            api.get(finalUrl)
                .then(res => {
                    if (res.status && res.status === 200) {
                        commit('SET_PRICES_PARTNER_FILES', res.data)
                    } else {
                        dispatch('showError', { errorMessage: res.data.msg || res.data.message })
                    }
                    resolve(res);
                })
                .catch(error => {
                    console.log('Problem', error.message);
                    dispatch('showError', { errorMessage: error.response?.data?.message || error.message })
                    reject(error);
                });
        })
    },
    async deletePricePartnerFile({commit, dispatch}, id) {
        return new Promise((resolve, reject) => {
            api.delete(`/partner-gas/delete-file/${id}`)
                .then(res => {
                    if (res.data.status && res.data.status === 200) {
                        commit('SHOW_REMOVE_FILE', false)
                        dispatch('showError', { errorMessage: res.data.msg || res.data.message, isSuccess: true })
                        dispatch('getPricesPartnerFiles');
                    } else {
                        commit('SHOW_REMOVE_FILE', false)
                        dispatch('showError', { errorMessage: res.data.msg || res.data.message })
                    }
                    resolve(res);
                })
                .catch(error => {
                    console.log('Problem', error.message);
                    commit('SHOW_REMOVE_FILE', false)
                    dispatch('showError', { errorMessage: error.response.data.message || error.message })
                    reject(error);
                });
        })
    },
    async deleteTxFile({commit, dispatch}, id) {
        return new Promise((resolve, reject) => {
            api.delete(`transaction/${id}/delete-transaction-file`)
                .then(res => {
                    if (res.data.status && res.data.status === 200) {
                        commit('SHOW_REMOVE_FILE', false)
                        dispatch('showError', { errorMessage: res.data.msg || res.data.message, isSuccess: true })
                        dispatch('getTransactionFiles');
                    } else {
                        commit('SHOW_REMOVE_FILE', false)
                        dispatch('showError', { errorMessage: res.data.msg || res.data.message })
                    }
                    resolve(res);
                })
                .catch(error => {
                    console.log('Problem', error.message);
                    commit('SHOW_REMOVE_FILE', false)
                    dispatch('showError', { errorMessage: error.response.data.message || error.message })
                    reject(error);
                });
        })
    },
    async getUsersSearch({commit, dispatch, rootState}, data) {
        return new Promise((resolve, reject) => {
            api.get(`user/get-users-search?search=${data.search}&type=${data.type}`)
                .then(res => {
                    console.log(res)
                    if (res.status && res.status === 200) {
                        commit('SET_MANAGERS', res.data)
                    } else {
                        dispatch('showError', { errorMessage: res.data.msg || res.data.message })
                    }
                    resolve(res);
                })
                .catch(error => {
                    console.log('Problem', error.message);
                   dispatch('showError', { errorMessage: error.response.data.message || error.message })
                    reject(error);
                });
        })
    },
    async getCardsSelect({commit, dispatch, rootState}, data = {}) {
      let str = '';
      if (data && data.customer_id) {
        str = `?filter[customer_id]=${data.customer_id}`
      }
      if (data && data.empty_customer) {
        str = `?empty_customer=true`
      }
        return new Promise((resolve, reject) => {
            api.get(`card/get-select-cards${str}`)
                .then(res => {
                    console.log(res)
                    if (res.status && res.status === 200) {
                        commit('SET_CARDS_SELECT', res.data)
                    } else {
                        dispatch('showError', { errorMessage: res.data.msg || res.data.message })
                    }
                    resolve(res);
                })
                .catch(error => {
                    console.log('Problem', error.message);
                   dispatch('showError', { errorMessage: error.response.data.message || error.message })
                    reject(error);
                });
        })
    },
    async downloadXLSXFile({commit, dispatch, rootState}, fileName) {
        return new Promise(async (resolve, reject) => {
            let url
            switch (fileName) {
                case 'пользователи': url = 'user/get-users-report-xlsx'
                    break;
                case 'карты': url = 'card/get-cards-report-xlsx'
                    break;
                case 'платежи': url = 'payment/get-payments-report-xlsx'
                    url = await getFinalUrl(url, rootState)
                    break;
                case 'клиенты': url = 'customer/export-customers-to-excel'
                    break;
            }
            let dateNow = new Date();
            let dd = String(dateNow.getDate()).padStart(2, '0');
            let mm = String(dateNow.getMonth() + 1).padStart(2, '0'); //January is 0!
            let yyyy = dateNow.getFullYear();
            api.get(url, {responseType: 'blob'})
                .then(res => {
                    const url = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `${fileName}-${dd + '.' + mm + '.' + yyyy}.xlsx`); //or any other extension
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                    resolve(res);
                })
                .catch(error => {
                    console.log('Problem', error.message);
                   dispatch('showError', { errorMessage: error.response.data.message || error.message })
                    reject(error);
                });
        })
    },
    async getFileByPath({commit, dispatch, rootState}, data) {
        return new Promise(async (resolve, reject) => {
            api.get(`file/get-file-xls?file_type=${data.file_type}&id=${data.id}`, {responseType: 'arraybuffer'})
                .then(res => {
                    resolve(res);
                })
                .catch(error => {
                    console.log('Problem', error.message);
                   dispatch('showError', { errorMessage: error.response.data.message || error.message })
                    reject(error);
                });
        })
    },
    async getFileByType({commit, dispatch, rootState}, data) {
        return new Promise(async (resolve, reject) => {
            api.get(`file/get-file-xls?file_type=${data.file_type}&id=${data.id}`, {responseType: 'blob'})
                .then(res => {
                  const url = window.URL.createObjectURL(new Blob([res.data]));
                  const link = document.createElement('a');
                  link.href = url;
                  link.setAttribute('download', `${data.fileName}`); //or any other extension
                  document.body.appendChild(link);
                  link.click();
                  link.remove();
                    resolve(res);
                })
                .catch(error => {
                    console.log('Problem', error.message);
                   dispatch('showError', { errorMessage: error.response.data.message || error.message })
                    reject(error);
                });
        })
    },
  async getSalaryReport({commit, dispatch, rootState}, data) {
    let url = `manager/generate-salary-report?file=true&month=${data.period.month}&year=${data.period.year}`

    if (data.manager_id) {
      url += `&manager_id=${data.manager_id}`
    }

    return new Promise((resolve, reject) => {
      api.get(`${url}`, {responseType: 'blob'})
        .then(res => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `зарплатный отчет--${data.period.month + '.' + data.period.year}.xlsx`); //or any other extension
          document.body.appendChild(link);
          link.click();
          link.remove();
          resolve(res);
        })
        .catch(error => {
          console.log('Problem', error.message);
          dispatch('showError', { errorMessage: error.response.data.message || error.message })
          reject(error);
        });
    })
  },
  async getCardsFromDelko({commit, dispatch}, id) {
    return new Promise((resolve, reject) => {
      api.get(`card/import-cards-from-delko?integration_id=${id}`)
        .then(res => {
          console.log(res)
          if (res.status && res.status === 200) {
          } else {
            dispatch('showError', { errorMessage: res.data.msg || res.data.message })
          }
          dispatch('getCards')
          resolve(res);
        })
        .catch(error => {
          console.log('Problem', error.message);
          reject(error);
          dispatch('showError', {errorMessage: error.response.data.message || error.message})
        });
    })
  },
  async syncCardStatusesFromDelko({commit, dispatch}) {
    return new Promise((resolve, reject) => {
      api.get(`card/sync-card-statuses-from-delko`)
        .then(res => {
          console.log(res)
          if (res.status && res.status === 200) {
          } else {
            dispatch('showError', { errorMessage: res.data.msg || res.data.message })
          }
          dispatch('getCards')
          resolve(res);
        })
        .catch(error => {
          console.log('Problem', error.message);
          reject(error);
          dispatch('showError', {errorMessage: error.response.data.message || error.message})
        });
    })
  },
  async getCardCatalog({commit, dispatch}, card_id) {
    return new Promise((resolve, reject) => {
      api.get(`/card/get-card-catalogs?card_id=${card_id}`)
        .then(res => {
          console.log(res)
          if (res.status && res.status === 200) {
            commit('SET_CARD_CATALOG', res.data)
          } else {
            dispatch('showError', { errorMessage: res.data.msg || res.data.message })
          }
          resolve(res);
        })
        .catch(error => {
          console.log('Problem', error.message);
          reject(error);
          dispatch('showError', {errorMessage: error.response.data.message || error.message})
        });
    })
  },
  async sendEmails({commit, dispatch}, data) {
    return new Promise((resolve, reject) => {
      api.post(`/notification/send-broadcast-email`, data)
        .then(res => {
          commit('TOGGLE_OVERLAY', false)
          commit('SET_SHOW_SEND_EMAILS_POPUP', false)
          dispatch('showError', { errorMessage: 'Сообщения отправлены клиентам', isSuccess: true })
          resolve(res);
        })
        .catch(error => {
          console.log('Problem', error.message);
          commit('SET_SHOW_SEND_EMAILS_POPUP', false)
          dispatch('showError', { errorMessage: error.response.data.message || error.message })
          reject(error);
        });
    })
  },
  async getAnalytics({commit, dispatch}, data) {
    commit('SET_ANALYTICS', null)
    return new Promise((resolve, reject) => {
      let str = data.date;
      if (data.client !== '') {
        str += `&customer_id=${data.client}`
      }
        api.get(`dashboard/analytics${str}`)//&range=${data.range}
            .then(res => {
                if (res.status === 200) {
                    commit('SET_ANALYTICS', res.data)
                    resolve(res)
                } else {
                    dispatch('showError', { errorMessage: res.data.msg || res.data.message })
                    reject(res)
                }
            })
            .catch(error => {
                console.log('Problem', error.message)
                dispatch('showError', { errorMessage: error.response?.data?.message || error.message })
                reject(error)
            })
    })
  },
  async getReconciliationReport({ commit, dispatch, rootState }, payloadObj) {
    let url = `/dashboard/act-sverka?start_date=${payloadObj.startDate}&end_date=${payloadObj.endDate}&customer_id=${payloadObj.customerId}`
    // let finalUrl = await getFinalUrl(url, rootState)

    return new Promise(async (resolve, reject) => {
      if (payloadObj.getFile) {
        url += `&returnFile=true`
        let dateNow = new Date()
        let dd = String(dateNow.getDate()).padStart(2, '0')
        let mm = String(dateNow.getMonth() + 1).padStart(2, '0')
        let yyyy = dateNow.getFullYear()

        api
          .get(`${url}`, { responseType: 'blob' })
          .then((res) => {
            const url = window.URL.createObjectURL(new Blob([res.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute(
              'download',
              `акт-сверки--${dd + '.' + mm + '.' + yyyy}.xlsx`
            )
            document.body.appendChild(link)
            link.click()
            link.remove()
            resolve(res)
          })
          .catch((error) => {
            console.log('Problem', error.message)
            dispatch('showError', { errorMessage: error.response.data.message || error.message })
            reject(error)
          })
      } else {
        api
          .get(url)
          .then((res) => {
            if (res.data?.message?.includes('нет')) {
              dispatch('showError', { errorMessage: res.data.msg || res.data.message })
            }
            commit('SET_RECONCILIATION_REPORT', res.data)
            resolve(res)
          })
          .catch((error) => {
            console.log('Problem', error.message)
            dispatch('showError', { errorMessage: error.response?.data?.message || error.message })
            reject(error)
          })
      }
    })
  }
};

export default actions;
